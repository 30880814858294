import moment from 'moment';

const allowedImageMimeTypes = [
  'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp', 'image/avif',
];

const restrictedFileExtensions = [
  'zip', 'rar', '7z', 'tar', 'xz', 'exe',
];

const repeatOptions = [
  { id: 'day', display_label: 'Repeat every # of days' },
  { id: 'week', display_label: 'Repeat every # of weeks' },
  { id: 'month', display_label: 'Repeat every # of months' },
];

const rfpReasonOptions = [
  { id: 'not_using_helix', display_label: 'I\'m buying from somewhere else' },
  { id: 'on_hold', display_label: 'Project/purchase is on hold for a later date' },
  { id: 'not_needed', display_label: 'This project/equipment is no longer needed' },
  { id: 'quote_incorrect', display_label: 'What was quoted is not what I\’m looking for' },
  { id: 'cost', display_label: 'Price is more than I expected' },
  { id: 'over_budget', display_label: 'Price is more than my budget allows for' },
  { id: 'other', display_label: 'Other' },
];

const rfpReasonOptionsSP = [
  { id: 'not_in_territory', display_label: 'Project is out of our territory' },
  { id: 'out_of_scope', display_label: 'Equipment/scope of work is outside our capabilities' },
  { id: 'under_staffed', display_label: 'Do not have anyone available to complete this job' },
  { id: 'needs_repair', display_label: 'Not interested in bidding this project ' },
  { id: 'other', display_label: 'Other' },
];

const rfpCancelReasonOptionsEO = [
  { id: 'no_longer_needed', display_label: 'Request/Project is no longer needed' },
  { id: 'fulfilled_outside', display_label: 'Request/Project is being fulfilled outside of the platform' },
  { id: 'too_long', display_label: 'Quoted lead time does not meet project timeline' },
  { id: 'on_hold', display_label: 'Project/purchase is on hold for a later date' },
  { id: 'no_budget', display_label: 'Budget for the Request/Project no longer exists' },
  { id: 'delayed', display_label: 'Request/Project has been pushed out for future review' },
  { id: 'other', display_label: 'Other' },
];

const rfpCancelReasonOptionsHelixAdmin = [
  { id: 'customer_request', display_label: 'Customer requested cancelation' },
  { id: 'no_vendors', display_label: 'No vendors currently available to fulfill the Request/Project' },
  { id: 'no_response', display_label: 'No action/response from end customer' },
  { id: 'outside_scope', display_label: 'Outside scope of platform' },
  { id: 'too_long', display_label: 'Fulfillment request timeline too short' },
  { id: 'other', display_label: 'Other' },
];

const rfpStatusOptions = [
  { id: 'draft', display_label: 'Draft' },
  { id: 'submitted', display_label: 'Submitted' },
  { id: 'matched', display_label: 'Matched' },
  { id: 'quoted', display_label: 'Quoted' },
  { id: 'service_in_progress', display_label: 'Service in Progress' },
  { id: 'complete', display_label: 'Completed' },
];

const rfpStatusOptionsSP = [
  { id: 'matched', display_label: 'Matched' },
  { id: 'quoted', display_label: 'Quoted' },
  { id: 'service_in_progress', display_label: 'Service in Progress' },
  { id: 'complete', display_label: 'Completed' },
];

const rfpTypeOptions = [
  { id: 'service_agreement', display_label: 'Service Agreement' },
  { id: 'one_time_service', display_label: 'One Time Service' },
  { id: 'parts_order', display_label: 'Parts Order' },
  { id: 'new_equipment', display_label: 'New Equipment' },
  { id: 'rental_equipment', display_label: 'Rental Equipment' },
  { id: 'energy_assessment', display_label: 'Energy Assessment' },
];

const statusOptions = [
  { id: 'operating', display_label: 'Operating', color: '#2E933C' },
  { id: 'backup', display_label: 'Backup / Spare', color: '#216093' },
  { id: 'maintenance_required', display_label: 'Maintenance Required', color: '#e9c547' },
  { id: 'needs_repair', display_label: 'Needs Repair / Down', color: '#E63462' },
  { id: 'scrap', display_label: 'Scrap', color: '#042b48' },
];

const stateOptions = [
  { id: 'AL', display_label: 'Alabama' },
  { id: 'AK', display_label: 'Alaska' },
  { id: 'AS', display_label: 'American Samoa' },
  { id: 'AZ', display_label: 'Arizona' },
  { id: 'AR', display_label: 'Arkansas' },
  { id: 'CA', display_label: 'California' },
  { id: 'CO', display_label: 'Colorado' },
  { id: 'CT', display_label: 'Connecticut' },
  { id: 'DE', display_label: 'Delaware' },
  { id: 'DC', display_label: 'District of Columbia' },
  { id: 'FM', display_label: 'Federated States of Micronesia' },
  { id: 'FL', display_label: 'Florida' },
  { id: 'GA', display_label: 'Georgia' },
  { id: 'GU', display_label: 'Guam' },
  { id: 'HI', display_label: 'Hawaii' },
  { id: 'ID', display_label: 'Idaho' },
  { id: 'IL', display_label: 'Illinois' },
  { id: 'IN', display_label: 'Indiana' },
  { id: 'IO', display_label: 'Iowa' },
  { id: 'KS', display_label: 'Kansas' },
  { id: 'KY', display_label: 'Kentucky' },
  { id: 'LA', display_label: 'Louisiana' },
  { id: 'ME', display_label: 'Maine' },
  { id: 'MH', display_label: 'Marshall Islands' },
  { id: 'MD', display_label: 'Maryland' },
  { id: 'MA', display_label: 'Massachusetts' },
  { id: 'MI', display_label: 'Michigan' },
  { id: 'MN', display_label: 'Minnesota' },
  { id: 'MS', display_label: 'Mississippi' },
  { id: 'MO', display_label: 'Missouri' },
  { id: 'MT', display_label: 'Montana' },
  { id: 'NE', display_label: 'Nebraska' },
  { id: 'NV', display_label: 'Nevada' },
  { id: 'NH', display_label: 'New Hampshire' },
  { id: 'NJ', display_label: 'New Jersey' },
  { id: 'NM', display_label: 'New Mexico' },
  { id: 'NY', display_label: 'New York' },
  { id: 'NC', display_label: 'North Carolina' },
  { id: 'ND', display_label: 'North Dakota' },
  { id: 'MP', display_label: 'Northern Mariana Islands' },
  { id: 'OH', display_label: 'Ohio' },
  { id: 'OK', display_label: 'Oklahoma' },
  { id: 'OR', display_label: 'Oregon' },
  { id: 'PW', display_label: 'Palau' },
  { id: 'PA', display_label: 'Pennsylvania' },
  { id: 'PR', display_label: 'Puerto Rico' },
  { id: 'RI', display_label: 'Rhode Island' },
  { id: 'SC', display_label: 'South Carolina' },
  { id: 'SD', display_label: 'South Dakota' },
  { id: 'TN', display_label: 'Tennessee' },
  { id: 'TX', display_label: 'Texas' },
  { id: 'UT', display_label: 'Utah' },
  { id: 'VT', display_label: 'Vermont' },
  { id: 'VI', display_label: 'Virgin Island' },
  { id: 'VA', display_label: 'Virginia' },
  { id: 'WA', display_label: 'Washington' },
  { id: 'WV', display_label: 'West Virginia' },
  { id: 'WI', display_label: 'Wisconsin' },
  { id: 'WY', display_label: 'Wyoming' },

  // canada
  { id: 'AB', display_label: 'Alberta' },
  { id: 'BC', display_label: 'British Columbia' },
  { id: 'MB', display_label: 'Manitoba' },
  { id: 'NB', display_label: 'New Brunswick' },
  { id: 'NL', display_label: 'Newfoundland and Labrador' },
  { id: 'NT', display_label: 'Northwest Territories' },
  { id: 'NS', display_label: 'Nova Scotia' },
  { id: 'NU', display_label: 'Nunavut' },
  { id: 'ON', display_label: 'Ontario' },
  { id: 'PE', display_label: 'Prince Edward Island' },
  { id: 'QC', display_label: 'Quebec' },
  { id: 'SK', display_label: 'Saskatchewan' },
  { id: 'YT', display_label: 'Yukon' },
];

const taskGroupColorOptions = [
  { name: 'blue', hex: '216093' },
  { name: 'dark-blue', hex: '042b48' },
  { name: 'light-blue', hex: '4FB0C6' },
  { name: 'blue-green', hex: '06BCC1' },
  { name: 'green', hex: '2E933C' },
  { name: 'red', hex: 'DB162F' },
  { name: 'pink', hex: 'E40066' },
  { name: 'magenta', hex: '802392' },
  { name: 'dark-purple', hex: '42253B' },
  { name: 'orange', hex: 'E18331' },
  { name: 'yellow', hex: 'F6AE2D' },
];

const termsOptions = [
  { id: 'payment_in_advance', display_label: 'Payment in Advance' },
  { id: 'net_30', display_label: 'NET 30' },
  { id: 'net_45', display_label: 'NET 45' },
  { id: 'net_60', display_label: 'NET 60' },
  { id: 'other', display_label: 'Other...' },
];

const operatingHourOptions = [
  { id: 'two_thousand', display_label: '2,000 hrs/yr OR 8 hrs/day, 5 days/ week' },
  { id: 'four_thousand', display_label: '4,000 hrs/yr OR 16 hrs/day, 5 days/ week' },
  { id: 'six_thousand', display_label: '6,000 hrs/yr OR 16 hrs/day, 7 days/ week' },
  { id: 'eight_thousand', display_label: '8,000 hrs/yr OR 24/7/365' },
];

const electricalInfoOptions = [
  { id: '115/1/60', display_label: '115/1/60' },
  { id: '200/3/60', display_label: '200/3/60' },
  { id: '208-230/1/60', display_label: '208-230/1/60' },
  { id: '208-230/3/60', display_label: '208-230/3/60' },
  { id: '230/1/60', display_label: '230/1/60' },
  { id: '230/3/60', display_label: '230/3/60' },
  { id: '460/3/60', display_label: '460/3/60' },
  { id: '575/3/60', display_label: '575/3/60' },
];

const physicalLocationOptions = [
  { id: 'exterior', display_label: 'Exterior' },
  { id: 'interior', display_label: 'Interior' },
];

const conditionOptions = [
  { id: 'excellent', display_label: 'Excellent' },
  { id: 'good', display_label: 'Good' },
  { id: 'fair', display_label: 'Fair' },
  { id: 'poor', display_label: 'Poor' },
];

const purchaseConditionOptions = [
  { id: 'new', display_label: 'New' },
  { id: 'used', display_label: 'Used' },
];

const maintenanceFrequencyOptions = [
  { id: 'daily', display_label: 'Daily' },
  { id: 'weekly', display_label: 'Weekly' },
  { id: 'monthly', display_label: 'Monthly' },
  { id: 'quarterly', display_label: 'Quarterly' },
  { id: 'yearly', display_label: 'Yearly' },
];

const booleanOptions = [
  { id: 'true', display_label: 'Yes' },
  { id: 'false', display_label: 'No' },
];

const typeOfServiceContractOptions = [
  { id: 'none', display_label: 'None' },
  { id: 'parts_only', display_label: 'Parts Only' },
  { id: 'preventative_maint', display_label: 'Preventive Maintenance' },
  { id: 'full_coverage', display_label: 'Full Coverage' },
];

const taskPriorityOptions = [
  { id: 0, display_label: 'None' },
  // { id: 1, display_label: 'Clear' },
  { id: 2, display_label: 'Low' },
  { id: 3, display_label: 'Normal' },
  { id: 4, display_label: 'High' },
  { id: 5, display_label: 'Urgent' },
];

const phoneTypeOptions = [
  { id: 'phone', display_label: 'Phone' },
  { id: 'cell', display_label: 'Cell' },
  { id: 'fax', display_label: 'Fax' },
];

const quoteStatusOptions = [
  { id: 'pending', display_label: 'Pending' },
  { id: 'withdrawn', display_label: 'Withdrawn' },
  { id: 'requote_requested', display_label: 'Requote Requested' },
  { id: 'rejected', display_label: 'Rejected' },
  { id: 'accepted', display_label: 'Accepted' },
];

const workOrderStatusOptions = [
  { id: 'pending_review', display_label: 'Pending Review' },
  { id: 'canceled', display_label: 'Canceled' },
  { id: 'denied', display_label: 'Denied' },
  { id: 'approved', display_label: 'Approved' },
  { id: 'in_progress', display_label: 'In Progress' },
  { id: 'work_complete', display_label: 'Work Complete' },
  { id: 'closed', display_label: 'Closed' },
];

const workOrderCancelReasonOptions = [
  { id: 'no_longer_needed', display_label: 'Request/Project is no longer needed' },
  { id: 'duplicate_request', display_label: 'Duplicate request' },
  { id: 'incorrect_information', display_label: 'Incorrect information' },
  { id: 'revised_request', display_label: 'Revised request' },
  { id: 'mistaken_submission', display_label: 'Mistaken submission' },
  { id: 'miscommunication', display_label: 'Miscommunication' },
  { id: 'other', display_label: 'Other' },
];

const workOrderDenyReasonOptions = [
  { id: 'duplicate_request', display_label: 'Duplicate request' },
  { id: 'lack_of_information', display_label: 'Lack of information' },
  { id: 'not_feasible', display_label: 'Not feasible' },
  { id: 'out_of_scope', display_label: 'Out of scope' },
  { id: 'resource_constraints', display_label: 'Resource constraints' },
  { id: 'budget_constraints', display_label: 'Budget constraints' },
  { id: 'regulatory_noncompliance', display_label: 'Regulatory non-compliance' },
  { id: 'not_an_organizational_priority', display_label: 'Not an organizational priority' },
  { id: 'no_longer_required', display_label: 'No longer required' },
  { id: 'other', display_label: 'Other' },
];

const reservationRequestCancelReasonOptions = [
  { id: 'plans_change', display_label: 'Change of plans' },
  { id: 'double_booked', display_label: 'Double booking' },
  { id: 'mistake', display_label: 'Mistake in details' },
  { id: 'unneeded', display_label: 'No longer needed' },
  { id: 'other', display_label: 'Other' },
];

const reservationRequestDenyReasonOptions = [
  { id: 'conflict', display_label: 'Conflict with existing reservation' },
  { id: 'maintenance_scheduled', display_label: 'Maintenance scheduled at that time' },
  { id: 'not_reservable', display_label: 'Location is not reservable' },
  { id: 'location_closed', display_label: 'Location is closed at that time' },
  { id: 'lacking_info', display_label: 'Lack of information' },
  { id: 'other', display_label: 'Other' },
];

const locationAttachmentTypes = {
  utility: { label: 'Utility Bills', saving: false },
  floor_plan: { label: 'Floor Plans / Site Maps', saving: false },
  service_contract: { label: 'Service Contracts', saving: false },
  insurance: { label: 'Insurance Documents', saving: false },
  compliance_certificates: { label: 'Compliance Certificates', saving: false },
  other: { label: 'Other Files', saving: false },
};

const equipmentAttachmentTypes = {
  user_manual: { label: 'User Manuals', saving: false },
  warranty: { label: 'Warranties', saving: false },
  certificate: { label: 'Certificates', saving: false },
  existing_service_contract: { label: 'Existing Service Contracts', saving: false },
  equipment: { label: 'Other Equipment Files', saving: false },
};

const partAttachmentTypes = {
  equipment: { label: '', saving: false },
};

const vendorAttachmentTypes = {
  contracts: { label: 'Contracts and Agreements', saving: false, api_field: 'contract_files' },
  compliance: { label: 'Compliance and Certifications', saving: false, api_field: 'compliance_files' },
  financial: { label: 'Financial Documents', saving: false, api_field: 'financial_files' },
  insurance: { label: 'Insurance and Legal', saving: false, api_field: 'insurance_files' },
  performance: { label: 'Performance and Communication', saving: false, api_field: 'communication_files' },
  other: { label: 'Other Files', saving: false, api_field: 'attachments' },
};

const TaskTableDisplayType = {
  DEFAULT: 'default',
  INSTANCE: 'instance',
  CALENDAR: 'calendar',
  COMPLETED: 'completed',
  RELATED_DETAIL_VIEW_ACTIVE: 'related_detail_view_active',
  RELATED_DETAIL_VIEW_COMPLETED: 'related_detail_view_completed',
  TASK_LIST_V1_COMPLETED: 'task_list_v1_completed',
};

const incentivePropertyTypes = [
  { id: 'general', display_label: 'General' },
  { id: 'multi_family', display_label: 'Multi-Family' },
  { id: 'multi_building', display_label: 'Multi-Building' },
  { id: 'planned_community', display_label: 'Planned Community' },
];

const taskWorkTypeOptions = [
  { id: 'preventative_maintenance', display_label: 'Preventive' },
  { id: 'reactive_maintenance', display_label: 'Reactive' },
  { id: 'predictive_maintenance', display_label: 'Predictive' },
  { id: 'deferred_maintenance', display_label: 'Deferred' },
  { id: 'other', display_label: 'Other' },
];

const reservationStatusOptions = [
  { id: 'approved', display_label: 'Approved' },
  { id: 'partial', display_label: 'Approved (Partial)' },
  { id: 'denied', display_label: 'Denied' },
  { id: 'canceled', display_label: 'Canceled' },
];

const locationReservationStatusOptions = [
  { id: 'approved', display_label: 'Approved' },
  { id: 'pending_review', display_label: 'Pending Review' },
];

const incentiveConstructionTypes = [
  'New Construction',
  'Existing Building',
  'Gut Rehab',
];

const riskScoreLevelHigh = 66;
const riskScoreLevelMedium = 33;

// methods ---------

const getEquipmentStatusLabel = (status) => {
  const option = statusOptions.find(s => s.id === status);
  return option ? option.display_label : 'Unknown';
};

const getEquipmentStatusColor = (status) => {
  const option = statusOptions.find(s => s.id === status);
  return option ? option.color : '#2E933C';
};

const getRFPTypeLabel = (type) => {
  const option = rfpTypeOptions.find(t => t.id === type);
  return option ? option.display_label : 'Unknown';
};

const getTaskPriorityLabel = (priority) => {
  const option = taskPriorityOptions.find(p => p.id === parseInt(priority));
  return option ? option.display_label : 'None';
};

const getTimeSlotOptions = (increment = 15, use24Hour = false) => {
  const timeSlots = [];
  const currentTime = moment('00:00', 'HH:mm');

  while (currentTime.isBefore(moment('24:00', 'HH:mm'))) {
    timeSlots.push({
      id: currentTime.format('HHmm'),
      display_label: use24Hour
        ? currentTime.format('HH:mm')
        : `${currentTime.format('h:mm')} ${currentTime.format('A')}`,
    });
    currentTime.add(increment, 'minutes');
  }

  return timeSlots;
};

export {
  allowedImageMimeTypes,
  restrictedFileExtensions,
  repeatOptions,
  rfpReasonOptions,
  rfpReasonOptionsSP,
  rfpStatusOptions,
  rfpStatusOptionsSP,
  rfpCancelReasonOptionsEO,
  rfpCancelReasonOptionsHelixAdmin,
  rfpTypeOptions,
  statusOptions,
  stateOptions,
  taskGroupColorOptions,
  termsOptions,
  locationAttachmentTypes,
  equipmentAttachmentTypes,
  partAttachmentTypes,
  vendorAttachmentTypes,

  operatingHourOptions,
  electricalInfoOptions,
  physicalLocationOptions,
  conditionOptions,
  purchaseConditionOptions,
  maintenanceFrequencyOptions,
  booleanOptions,
  typeOfServiceContractOptions,
  taskPriorityOptions,
  phoneTypeOptions,
  quoteStatusOptions,
  taskWorkTypeOptions,

  incentivePropertyTypes,
  incentiveConstructionTypes,

  riskScoreLevelHigh,
  riskScoreLevelMedium,

  workOrderStatusOptions,
  workOrderCancelReasonOptions,
  workOrderDenyReasonOptions,
  reservationRequestCancelReasonOptions,
  reservationRequestDenyReasonOptions,

  reservationStatusOptions,
  locationReservationStatusOptions,

  getEquipmentStatusLabel,
  getEquipmentStatusColor,
  getRFPTypeLabel,
  getTaskPriorityLabel,
  getTimeSlotOptions,

  TaskTableDisplayType,
};
