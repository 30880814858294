import moment from 'moment';

import BaseAPIClient from './base/BaseAPIClient';
import CommentModel from '../models/CommentModel';
import ListResponseModel from '../models/ListResponseModel';
import { ReservationRequestModel, ReservationModel } from '../models/ReservationModel';
import { ReservationRequestUiSettingsModel, ReservationUiSettingsModel } from '../models/UiSettingsModels';
import ReservationLogModel from '../models/ReservationLogModel';
import ReservationRequestLogModel from '../models/ReservationRequestLogModel';

export default class ReservationClient extends BaseAPIClient {
  createReservationRequest({
    name,
    description,
    reservations,
    reminderDays,
    attachments,
    reviewers = null,
  }) {
    const data = {
      name,
      description,
      reminder_number_of_days: reminderDays,
    };

    if (reservations) {
      // flatten ReservationModel, bare bones for API
      data.reservations = reservations.map(r => ({
        location: r.location.id,
        start_time: r.start_time,
        end_time: r.end_time,
      }));
    }

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    if (reviewers) {
      data.reviewers = reviewers;
    }

    return this._post('reservations/requests/', data).then(request => new ReservationRequestModel(request));
  }

  getReservationRequests({
    search = '',
    ordering = 'name',
    offset = 0,
    limit = 50,
    status = '',
    reviewed_statuses = null,
    requester,
    location,
    date_after,
    date_before,
    reviewers = null,
    reviewed_by = null,
    no_assigned_reviewer = null,
  }) {
    const params = {
      search,
      ordering,
      offset,
      limit,
      status__in: status,
      reservations__location_id: location,
      reservations__start_time_after: date_after,
      reservations__end_time_before: date_before,
    };

    if (reviewed_statuses?.length) {
      params.reviewed_status__in = reviewed_statuses.join(',');
    }

    if (requester) {
      params.user__id = requester;
    }

    if (reviewers !== null) {
      params.reviewers = reviewers;
    }

    if (reviewed_by !== null) {
      params.reviewed_by = reviewed_by;
    }

    if (no_assigned_reviewer !== null) {
      params.no_assigned_reviewer = no_assigned_reviewer;
    }

    return this._get('reservations/requests/', params).then(r => new ListResponseModel(r, ReservationRequestModel));
  }

  getReservationRequest(id) {
    return this._get(`reservations/requests/${id}/`).then(request => new ReservationRequestModel(request));
  }

  getReservationRequestLogs({
    reservation_request_id,
    limit = 50,
    offset = 0,
    include_comments = null,
    ordering = 'created',
    field = null,
  }) {
    const params = {
      limit,
      offset,
      ordering,
    };

    if (include_comments !== null) {
      params.include_comments = include_comments;
    }

    if (field) {
      params.field = field;
    }

    return this._get(`reservations/requests/${reservation_request_id}/logs/`, params).then(m => new ListResponseModel(m, ReservationRequestLogModel));
  }

  cancelReservationRequest(requestId, reason, detail) {
    const data = {
      canceled_reason: reason,
      additional_reason: detail,
    };
    return this._post(`reservations/requests/${requestId}/cancel/`, data);
  }

  reviewReservationRequest(requestId, reservations, deny_reason = null, deny_detail = null) {
    const data = { reservations };

    if (deny_reason) {
      data.denied_reason = deny_reason;
    }

    if (deny_detail) {
      data.additional_reason = deny_detail;
    }

    return this._post(`reservations/requests/${requestId}/review/`, data).then(r => new ReservationRequestModel(r));
  }

  updateReservationRequestMeta(requestId, name, description) {
    const data = {
      name,
      description,
    };
    return this._patch(`reservations/requests/${requestId}/`, data).then(request => new ReservationRequestModel(request));
  }

  updateReservationRequestReviewers(requestId, reviewers) {
    const data = {
      reviewers: reviewers.map(i => i?.id || i),
    };
    return this._patch(`reservations/requests/${requestId}/`, data).then(request => new ReservationRequestModel(request));
  }

  updateReservationRequestAttachments(requestId, attachments) {
    const data = {
      attachments: attachments.map(f => f.id),
    };
    return this._patch(`reservations/requests/${requestId}/`, data).then(r => new ReservationRequestModel(r));
  }

  addReservationRequestComment(requestId, message) {
    return this._post(`reservations/requests/${requestId}/comments/`, { message }).then(c => new CommentModel(c));
  }

  async checkAvailability(reservations) {
    const transformReservations = resList => (
      resList.map(r => ({
        start_time: r.start_time || '',
        end_time: r.end_time || '',
        location: r.location?.id || r.location || 0,
      }))
    );

    const reconcileAvailability = (results) => {
      reservations.forEach((res, k) => {
        res.available = (typeof results[k] !== 'undefined') ? results[k] : true;
      });
      return reservations;
    };

    const data = { reservations: transformReservations(reservations) };

    const response = await this._post('reservations/check_availability/', data);
    return reconcileAvailability(response.availability_results);
  }

  getReservationRequestUiSettings() {
    return this._get('reservations/requests/ui_settings/').then(settings => new ReservationRequestUiSettingsModel(settings));
  }

  updateReservationRequestUiSettings({
    search = null,
    sort = null,
    requester = null,
    status = null,
    locations = null,
    date_after = null,
    date_before = null,
    reviewers = null,
    no_assigned_reviewer = null,
  }) {
    const data = {
      search,
      sort,
      status,
      locations,
      reviewers,
      no_assigned_reviewer,
    };

    if (!requester || requester === 0) {
      data.requester = null;
    } else if (requester?.id) {
      data.requester = requester.id;
    } else {
      data.requester = requester;
    }

    if (date_after) {
      data.date_after = moment.utc(date_after).format('YYYY-MM-DDTHH:mm:ss[Z]');
    } else {
      data.date_after = null;
    }

    if (date_before) {
      data.date_before = moment.utc(date_before).format('YYYY-MM-DDTHH:mm:ss[Z]');
    } else {
      data.date_before = null;
    }

    if (!locations?.length || locations?.[0] === undefined) {
      data.locations = [];
    }

    if (!reviewers) {
      data.reviewers = [];
    } else {
      data.reviewers = reviewers.map(r => r.id);
    }

    if (no_assigned_reviewer === null) {
      data.no_assigned_reviewer = false;
    }

    return this._patch('reservations/requests/ui_settings/', data).then(settings => new ReservationRequestUiSettingsModel(settings));
  }

  /// // Reservation Requests ↑

  /// // ------

  /// // Reservations ↓

  getReservations({
    search = '',
    ordering = 'request__name',
    offset = 0,
    limit = 50,
    archived = null,
    location,
    date_after,
    date_before,
    requesters = null,
    status = '',
    forSelectUi = false,
    end_time__gte = null,
  }) {
    const params = {
      search,
      ordering,
      offset,
      limit,
      location,
      start_time_after: date_after,
      end_time_before: date_before,
    };

    if (requesters !== null) {
      params.requesters = requesters;
    }

    if (status) {
      if (Array.isArray(status) && status.length) {
        params.status__in = status.join(',');
      } else {
        params.status = status;
      }
    }

    if (archived !== null) {
      params.archived = archived;
    }

    if (end_time__gte !== null) {
      params.end_time__gte = end_time__gte;
    }

    const url = forSelectUi ? 'reservations/select_ui/' : 'reservations/';

    return this._get(url, params).then(r => new ListResponseModel(r, ReservationModel));
  }

  getReservation(reservationId) {
    return this._get(`reservations/${reservationId}/`).then(r => new ReservationModel(r));
  }

  updateReservation(reservationId, location, start_time, end_time) {
    return this._patch(`reservations/${reservationId}/`, {
      location,
      start_time,
      end_time,
    }).then(r => new ReservationModel(r));
  }

  cancelReservation(reservationId) {
    return this._post(`reservations/${reservationId}/cancel/`);
  }

  getReservationUiSettings() {
    return this._get('reservations/ui_settings/').then(settings => new ReservationUiSettingsModel(settings));
  }

  updateReservationNotifyDays(reservationId, days = 1) {
    return this._patch(`reservations/${reservationId}/`, { notify_days_before_end_time: days }).then(r => new ReservationModel(r));
  }

  updateReservationUiSettings({
    search = null,
    sort = null,
    locations = null,
    date_after = null,
    date_before = null,
    requesters = null,
  }) {
    const data = {
      search,
      sort,
      locations,
    };

    if (date_after) {
      data.date_after = moment.utc(date_after).format('YYYY-MM-DDTHH:mm:ss[Z]');
    } else {
      data.date_after = null;
    }

    if (date_before) {
      data.date_before = moment.utc(date_before).format('YYYY-MM-DDTHH:mm:ss[Z]');
    } else {
      data.date_before = null;
    }

    if (!locations?.length || locations?.[0] === undefined) {
      data.locations = [];
    }

    if (!requesters) {
      data.requesters = [];
    } else {
      data.requesters = requesters.map(r => r.id);
    }

    return this._patch('reservations/ui_settings/', data).then(settings => new ReservationUiSettingsModel(settings));
  }

  getReservationLogs({
    reservation_id,
    limit = 50,
    offset = 0,
    include_comments = null,
    ordering = 'created',
    field = null,
  }) {
    const params = {
      limit,
      offset,
      ordering,
    };

    if (include_comments !== null) {
      params.include_comments = include_comments;
    }

    if (field) {
      params.field = field;
    }

    return this._get(`reservations/${reservation_id}/logs/`, params).then(m => new ListResponseModel(m, ReservationLogModel));
  }

  addReservationComment(reservationId, message) {
    return this._post(`reservations/${reservationId}/comments/`, { message }).then(c => new CommentModel(c));
  }
}
