import moment from 'moment';
import Vue from 'vue';

import { applyEquipmentTypeFilterToApiParams } from '@/util';
import BaseAPIClient from './base/BaseAPIClient';
import ListResponseModel from '../models/ListResponseModel';

import UserModel from '../models/UserModel';
import UserSummaryModel from '../models/UserSummaryModel';
import CompanyModel from '../models/CompanyModel';
import { PropertyLocationModel } from '../models/LocationModels';
import CompanyEquipmentModel from '../models/CompanyEquipmentModel';
import RFPModel from '../models/RFPModel';
import RFPQuoteRequestModel from '../models/RFPQuoteRequestModel';
import QuoteModel from '../models/QuoteModel';
import InvoiceModel from '../models/InvoiceModel';
import CompanyEquipmentLogModel from '../models/CompanyEquipmentLogModel';
import PartModel from '../models/PartModel';
import OptionModel from '../models/OptionModel';
import ServiceReportLogModel from '../models/ServiceReportLogModel';
import ServiceModel from '../models/ServiceModel';
import StripeAccountModel from '../models/StripeAccountModel';
import PaymentMethodModel from '../models/PaymentMethodModel';
import TransactionModel from '../models/TransactionModel';
import CompanyRoleModel from '../models/CompanyRoleModel';
import CompanyPermissionModel from '../models/CompanyPermissionModel';
import TerritoryModel from '../models/TerritoryModel';
import TagModel from '../models/TagModel';
import CustomFieldModel from '../models/CustomFieldModel';
import NAICCodeModel from '../models/NAICCodeModel';
import LocationEquipmentModel from '../models/LocationEquipmentModel.js';
import EquipmentStatusModel from '../models/EquipmentStatusModel';
import EquipmentTypeModel from '../models/EquipmentTypeModel';
import EquipmentAgeModel from '../models/EquipmentAgeModel';
import TaskModel from '../models/TaskModel';
import TaskInstanceModel from '../models/TaskInstanceModel';
import TaskLogModel from '../models/TaskLogModel';
import TaskStatusModel from '../models/TaskStatusModel';
import { TaskChecklistModel } from '../models/TaskChecklistModel';
import CommentModel from '../models/CommentModel';
import PartContainerModel from '../models/PartContainerModel';
import PartContainerInventoryModel from '../models/PartContainerInventoryModel';
import PartContainerInventoryLogModel from '../models/PartContainerInventoryLogModel';
import VendorCompanyModel from '../models/VendorCompanyModel';
import ProjectModel from '../models/ProjectModel';
import IncentiveReportModel from '../models/IncentiveReportModel';
import IncentiveReportPricingTierModel from '../models/IncentiveReportPricingTierModel';
import CompanyEquipmentCategoryModel from '../models/CompanyEquipmentCategoryModel';
import EquipmentFieldModel from '../models/EquipmentFieldModel';
import RiskAssessmentSurveyModel from '../models/RiskAssessmentSurveyModel';
import LocationRelatedItemsModel from '../models/LocationRelatedItemsModel';
import {
  SPDashboardCountsModel,
  SPDasboardPerformanceMetricsModel,
  RFPTypeCountModel } from '../models/MetricModels';
import { ProjectsFilterModel } from '../models/ProjectsFilterModel';
import { EquipmentUiSettingsModel, LocationUiSettingsModel, PartUiSettingsModel, TaskTemplateUiSettingsModel } from '../models/UiSettingsModels';
import IncentiveReportPromoCodeModel from '../models/IncentiveReportPromoCodeModel';
import CsvExportModel from '../models/CsvExportModel';
import AutomatedWorkflowModel from '../models/AutomatedWorkflowModel';
import TaskTemplateModel from '../models/TaskTemplateModel';

const WorkType = TaskModel.WorkType;

export default class CompanyClient extends BaseAPIClient {
  getDashboard() {
    return this._get('company/stats/');
  }

  deletePendingInvite(id) {
    return this._delete(`company/invite/${id}/`);
  }

  clearDemoData() {
    return this._post('company/clear_demo_data/');
  }

  getUserSummary() {
    return this._get('company/users/summary/').then(m => new ListResponseModel(m, UserSummaryModel));
  }

  getCompanyUsers(search = '', ordering = 'last_name', format = '', limit = 50, offset = 0) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    let url = 'company/users/';
    if (format) {
      params.format = format;
      if (format == 'csv') {
        url = 'company/users_csv/';
      }
    }

    const promise = this._get(url, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, UserModel));
  }

  getPendingCompanyUsers(search = '', ordering = 'last_name', format = '', limit = 50, offset = 0) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    if (format) {
      params.format = format;
    }

    const promise = this._get('company/invites/', params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, UserModel));
  }

  resendCompanyInvite(id) {
    return this._post(`company/invite/${id}/resend/`);
  }

  getUserSearch({ search, ordering = 'last_name', limit = 10, exclude_user_ids = null, returnAll = false }) {
    const params = {
      search,
      ordering,
    };

    if (returnAll) {
      params.return_all = returnAll;
    } else {
      params.limit = limit;
    }

    if (exclude_user_ids?.length) {
      params.user_id__not_in = exclude_user_ids.join(',');
    }
    return this._get('company/users/select_ui/', params).then(u => new ListResponseModel(u, UserModel));
  }

  createCompany({
    name,
    street_address,
    latitude,
    longitude,
    street_address_line_2,
    city,
    state,
    zip,
    phone,
    phone_ext,
    is_equipment_operator,
    is_requesting_service_provider_access,
    add_user_as_admin,

    industry,
    industry_other,
    naic_code,
    how_did_you_hear_about_us,
    how_did_you_hear_about_us_explanation,
    hours_of_operation,
    affiliate,
  }) {
    const data = {
      name,
      street_address,
      location: latitude || longitude ? {
        latitude,
        longitude,
      } : null,
      street_address_line_2,
      city,
      state,
      zip,
      phone: (phone || '').replace(/[^0-9+]/g, ''),
      phone_ext: (phone_ext || ''),
      is_equipment_operator,
      is_requesting_service_provider_access,
      add_user_as_admin,

      industry,
      industry_other,
      naic_code: naic_code || null,
      how_did_you_hear_about_us,
      how_did_you_hear_about_us_explanation,
    };

    if (hours_of_operation) {
      data.hours_of_operation = hours_of_operation;
    }

    if (industry_other && !industry) {
      data.industry = 'other';
    }

    if (affiliate) {
      data.affiliate = affiliate;
    }

    if (how_did_you_hear_about_us_explanation && !how_did_you_hear_about_us) {
      data.how_did_you_hear_about_us = 'other';
    }

    return this._post('company/', data).then(e => new CompanyModel(e));
  }

  getCompany() {
    return this._get('company/').then(e => new CompanyModel(e));
  }

  getCompanyShadow() {
    return this._get('company/shadow/').then(e => new CompanyModel(e));
  }

  getCompanyById(id) {
    return this._get(`company/${id}/`).then(e => new CompanyModel(e));
  }

  editCompany({
    name,
    email,
    street_address,
    latitude,
    longitude,
    street_address_line_2,
    city,
    state,
    zip,
    phone,
    phone_ext,
    website,

    territories,

    tax_exempt,
    tax_exempt_document,

    equipment_manufacturers_serviced,
    equipment_categories_serviced,
    services_you_provide,

    // is_equipment_operator,
    // is_service_provider,

    industry,
    industry_other,
    naic_code,
    how_did_you_hear_about_us,
    how_did_you_hear_about_us_explanation,

    hours_of_operation,
    description,

    vendor_hub_special_offer,
    vendor_hub_special_offer_title,
  }) {
    const data = {
      name,
      email,
      street_address,
      location: latitude || longitude ? {
        latitude,
        longitude,
      } : null,
      street_address_line_2,
      city,
      state,
      zip,
      phone: (phone || '').replace(/[^0-9+]/g, ''),
      phone_ext: (phone_ext || ''),
      website: website || '',

      tax_exempt,
      territories: (territories || []),

      equipment_manufacturers_serviced: (equipment_manufacturers_serviced || []).map(a => a.id),
      equipment_categories_serviced: (equipment_categories_serviced || []),
      services_you_provide: (services_you_provide || []).map(a => a.id),

      // is_equipment_operator,
      // is_service_provider,

      industry,
      industry_other,
      naic_code: naic_code || null,
      how_did_you_hear_about_us,
      how_did_you_hear_about_us_explanation,
      description,
    };

    if (hours_of_operation) {
      data.hours_of_operation = hours_of_operation;
    }

    if (tax_exempt_document) {
      data.tax_exempt_document = tax_exempt_document;
    }

    if (vendor_hub_special_offer) {
      data.vendor_hub_special_offer = vendor_hub_special_offer;
    } else if (vendor_hub_special_offer !== undefined) {
      data.vendor_hub_special_offer = null;
    }

    if (vendor_hub_special_offer_title) {
      data.vendor_hub_special_offer_title = vendor_hub_special_offer_title;
    } else if (vendor_hub_special_offer_title !== undefined) {
      data.vendor_hub_special_offer_title = null;
    }

    return this._patch('company/', data).then(e => new CompanyModel(e));
  }

  setImage(logo) {
    return this._patch('company/', {
      logo,
    }).then(e => new CompanyModel(e));
  }

  setRequestSP() {
    return this._patch('company/', {
      is_requesting_service_provider_access: true,
    }).then(e => new CompanyModel(e));
  }

  getMatchedClients(search, ordering, limit, offset) {
    const params = {
      search,
      limit,
      ordering,
      offset,
    };
    return this._get('provider/clients/', params).then(m => new ListResponseModel(m, CompanyModel));
  }

  getClientRfps(id, limit = 5000, offset = 0) {
    const params = {
      limit,
      offset,
    };

    return this._get(`provider/company/${id}/quote_requested_rfps/`, params).then(m => new ListResponseModel(m, RFPQuoteRequestModel));
  }

  getMatchedVendors() {
    return this._get('company/vendors/').then(m => new ListResponseModel(m, CompanyModel));
  }

  getManufacturers({ search = '', limit = 5000, offset = 0, return_all = false }) {
    const params = {
      search,
      ordering: 'name',
    };

    if (return_all) {
      params.return_all = true;
    } else {
      params.limit = limit;
      params.offset = offset;
    }

    return this._get('equipment_manufacturers/', params).then(m => new ListResponseModel(m, OptionModel)).then((res) => {
      if (res && res.items) {
        const list = res.items;
        list.sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
        return list;
      }
      return [];
    }).catch(() => []);
  }

  getServices(search = '', limit = 5000, offset = 0) {
    const params = {
      search,
      limit,
      offset,
    };

    return this._get('company/service_types/', params).then(m => new ListResponseModel(m, OptionModel));
  }

  getNAICCodes() {
    return this._get('naic_codes/').then(m => new ListResponseModel(m, NAICCodeModel));
  }

  getEquipmentCategories({ search = '', limit = 5000, offset = 0, return_all = false }) {
    const params = {
      search,
    };

    if (return_all) {
      params.return_all = true;
    } else {
      params.limit = limit;
      params.offset = offset;
    }

    return this._get('equipment_categories/', params).then(m => new ListResponseModel(m, OptionModel));
  }

  getLocationCategories() {
    const params = {
      return_all: true,
    };

    return this._get('company/locations/categories/', params).then(m => new ListResponseModel(m, OptionModel));
  }

  getCompanyLocations({
    search = '',
    ordering = 'full_path_name',
    format = '',
    limit = 50,
    offset = 0,
    tags,
    hasParent,
    parent,
    query = '',
    forFilterSearch = false,
    mapBoundsFilter,
    category,
    archived,
    ancestorArchived,
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      archived,
      ancestor_archived: ancestorArchived,
    };

    if (parent) {
      params.parent = parent;
    }

    if (hasParent !== undefined) {
      params.has_parent = hasParent;
    }

    if (tags && tags.length) {
      params.tags__or = tags.map(t => t.id).join(',');
    }

    if (format) {
      params.format = format;
    }

    if (query && !params.search) {
      params.search = query;
    }

    if (category) {
      params.category__ancestor_hierarchy__ancestor_id = category;
    }

    if (mapBoundsFilter) {
      params.map_bounds = mapBoundsFilter;
    }

    const url = forFilterSearch ? 'company/locations/filter_search/' : 'company/locations/';

    const promise = this._get(url, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, PropertyLocationModel));
  }

  getCompanyLocationTreeUiData() {
    return this._get('company/locations/tree_ui/').then(m => new ListResponseModel(m, PropertyLocationModel));
  }

  getCompanyLocationTypeahead(search = '', field = 'name') {
    const params = {
      search,
    };
    return this._get(`company/locations/${field}/typeahead/`, params);
  }

  getCompanyMapLocations({
    search = '',
    hasParent,
    tags,
    category,
    archived = false,
  }) {
    const params = {
      search,
      archived,
    };

    if (hasParent !== undefined) {
      params.has_parent = hasParent;
    }

    if (tags && tags.length) {
      params.tags__or = tags.map(t => t.id).join(',');
    }

    if (category) {
      params.category__ancestor_hierarchy__ancestor_id = category;
    }

    return this._get('company/locations/map/', params).then(l => new ListResponseModel(l, PropertyLocationModel));
  }

  getClientMapLocations(search = '', hasParent = undefined) {
    const params = {
      search,
    };

    if (hasParent !== undefined) {
      params.has_parent = hasParent;
    }

    return this._get('provider/clients/map/', params).then(l => new ListResponseModel(l, PropertyLocationModel));
  }

  getChildLocations(parent, search = '', ordering = 'name', format = '', limit = 50, offset = 0) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      parent,
      format,
    };

    const promise = this._get('company/locations/', params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, PropertyLocationModel));
  }

  searchCompanyVendors({ search, offset = 0, limit = 10, blockRelatedCompanyVendors = true, onPlatformFilter = null, excludeVendorIds = null }) {
    const data = {
      search,
      offset,
      limit,
      block_related_company_vendors: blockRelatedCompanyVendors,
    };

    if (onPlatformFilter !== null) {
      data.on_platform = onPlatformFilter;
    }

    if (excludeVendorIds?.length) {
      data.id__not_in = excludeVendorIds.join(',');
    }

    return this._get('companies/providers/select_ui/', data).then(m => new ListResponseModel(m, VendorCompanyModel));
  }

  createProperty({
    name,
    tags,
    street_address,
    latitude,
    longitude,
    street_address_line_2,
    city,
    state,
    zip,
    square_footage,
    qr_code,
    description,
    attachments, // [FileModel,...]
    image,
    images, // [FileModel,...]
    parent,
    children,
    hours_of_operation,

    equipmentAdded,
    equipmentRemoved,
    partsAdded,
    partsRemoved,

    phone,

    story_count,
    building_count,
    room_count,
    year_built,
    foundation_type,
    construction_type,
    roof_system,
    roof_installation_year,
    garage_parking_material,
    garage_parking_installation_year,
    window_material,
    window_installation_year,
    light_ballasts_count,
    lighting_interior_type,
    lighting_interior_installation_year,
    lighting_exterior_type,
    lighting_exterior_installation_year,
    wiring,
    water_provider,
    electric_provider,
    gas_provider,
    heating_provider,
    sewer_provider,
    communication_provider,
    has_sprinklers,
    has_smoke_alarms,
    has_security_system,
    wind_hazard,
    flood_hazard,
    approximate_building_value,
    content_value,
    rent_value,
    total_insured_value,
    building_square_footage,
    lot_square_footage,
    office_square_footage,
    retail_square_footage,
    rentable_square_footage,
    common_area_square_footage,
    garage_parking_square_footage,
    misc_square_footage,
    vacant_land_acres,
    pool_count,
    customFields,
    removedCustomFields,
    remodel_summary,
    remodel_year,
    floor_type_1,
    floor_type_2,
    cabinet_type,
    countertop_type,
    wall_coverings,
    categoryId,
    categoryOther,
  }) {
    const data = {
      name,
      street_address,
      tags: tags || [],
      location: latitude || longitude ? {
        latitude,
        longitude,
      } : null,
      street_address_line_2,
      city,
      state,
      zip,
      square_footage,
      qr_code,
      description,
      parent,
      phone: (phone || '').replace(/[^0-9+]/g, ''),
      children: (children || []).map(c => ({ id: c.id })),

      story_count: story_count !== '' ? story_count : null,
      building_count: building_count !== '' ? building_count : null,
      room_count: room_count !== '' ? room_count : null,
      year_built: year_built !== '' ? year_built : null,
      foundation_type,
      construction_type,
      roof_system,
      roof_installation_year: roof_installation_year ? parseInt(roof_installation_year) : null,
      garage_parking_material,
      garage_parking_installation_year: garage_parking_installation_year ? parseInt(garage_parking_installation_year) : null,
      window_material,
      window_installation_year: window_installation_year ? parseInt(window_installation_year) : null,
      light_ballasts_count: light_ballasts_count !== '' ? light_ballasts_count : null,
      lighting_interior_type,
      lighting_interior_installation_year: lighting_interior_installation_year ? parseInt(lighting_interior_installation_year) : null,
      lighting_exterior_type,
      lighting_exterior_installation_year: lighting_exterior_installation_year ? parseInt(lighting_exterior_installation_year) : null,
      wiring,
      water_provider,
      electric_provider,
      gas_provider,
      heating_provider,
      sewer_provider,
      communication_provider,
      has_sprinklers,
      has_smoke_alarms,
      has_security_system,
      wind_hazard,
      flood_hazard,
      approximate_building_value,
      content_value,
      rent_value,
      total_insured_value,
      building_square_footage: building_square_footage !== '' ? building_square_footage : null,
      lot_square_footage: lot_square_footage !== '' ? lot_square_footage : null,
      office_square_footage: office_square_footage !== '' ? office_square_footage : null,
      retail_square_footage: retail_square_footage !== '' ? retail_square_footage : null,
      rentable_square_footage: rentable_square_footage !== '' ? rentable_square_footage : null,
      common_area_square_footage: common_area_square_footage !== '' ? common_area_square_footage : null,
      garage_parking_square_footage: garage_parking_square_footage !== '' ? garage_parking_square_footage : null,
      misc_square_footage: misc_square_footage !== '' ? misc_square_footage : null,
      vacant_land_acres: vacant_land_acres !== '' ? vacant_land_acres : null,
      pool_count: pool_count !== '' ? pool_count : null,
      hours_of_operation: null,
      remodel_summary,
      remodel_year: remodel_year ? parseInt(remodel_year) : null,
      floor_type_1,
      floor_type_2,
      cabinet_type,
      countertop_type,
      wall_coverings,
      category: categoryId || null,
      category_other: categoryOther,
    };

    const to_add_custom_fields = (customFields || []).filter(c => !!c.value);
    const to_remove_custom_field_ids = (removedCustomFields || []).map(f => f.id);

    if (to_add_custom_fields.length) {
      data.to_add_custom_fields = to_add_custom_fields;
    }

    if (to_remove_custom_field_ids.length) {
      data.to_remove_custom_field_ids = to_remove_custom_field_ids;
    }

    if (hours_of_operation) {
      data.hours_of_operation = hours_of_operation;
    }

    if (equipmentAdded && equipmentAdded.length) {
      data.equipment_added = [...new Set(equipmentAdded)];
    }

    if (partsAdded && partsAdded.length) {
      data.parts_added = [...new Set(partsAdded)];
    }

    if (equipmentRemoved && equipmentRemoved.length) {
      data.equipment_removed = [...new Set(equipmentRemoved)];
    }

    if (partsRemoved && partsRemoved.length) {
      data.parts_removed = [...new Set(partsRemoved)];
    }

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    data.image = image ? image.id : null;
    if (images) {
      data.images = images.map(f => f.id);
    }
    return this._post('company/locations/', data).then(e => PropertyLocationModel.factory(e));
  }

  addCompanyLocationRelatedFields(id, {
    equipmentAdded,
    partsAdded,
  }) {
    const data = {};

    if (equipmentAdded && equipmentAdded.length) {
      data.equipment_added = [...new Set(equipmentAdded)];
    }

    if (partsAdded && partsAdded.length) {
      data.parts_added = [...new Set(partsAdded)];
    }

    return this._patch(`company/locations/${id}/`, data).then(e => PropertyLocationModel.factory(e));
  }

  editCompanyLocation(id, {
    name,
    tags,
    street_address,
    latitude,
    longitude,
    street_address_line_2,
    city,
    state,
    zip,
    square_footage,
    qr_code,
    description,
    attachments, // [FileModel,...]
    image,
    images, // [FileModel,...]
    parent,
    children,
    hours_of_operation,

    equipmentAdded,
    equipmentRemoved,
    partsAdded,
    partsRemoved,

    phone,

    story_count,
    building_count,
    room_count,
    year_built,
    foundation_type,
    construction_type,
    roof_system,
    roof_installation_year,
    garage_parking_material,
    garage_parking_installation_year,
    window_material,
    window_installation_year,
    light_ballasts_count,
    lighting_interior_type,
    lighting_interior_installation_year,
    lighting_exterior_type,
    lighting_exterior_installation_year,
    wiring,
    water_provider,
    electric_provider,
    gas_provider,
    heating_provider,
    sewer_provider,
    communication_provider,
    has_sprinklers,
    has_smoke_alarms,
    has_security_system,
    wind_hazard,
    flood_hazard,
    approximate_building_value,
    content_value,
    rent_value,
    total_insured_value,
    building_square_footage,
    lot_square_footage,
    office_square_footage,
    retail_square_footage,
    rentable_square_footage,
    common_area_square_footage,
    garage_parking_square_footage,
    misc_square_footage,
    vacant_land_acres,
    pool_count,
    customFields,
    removedCustomFields,
    remodel_summary,
    remodel_year,
    floor_type_1,
    floor_type_2,
    cabinet_type,
    countertop_type,
    wall_coverings,
    categoryId,
    categoryOther,

    archived = false,
  }) {
    const data = {
      name,
      street_address,
      tags: tags || [],
      location: latitude || longitude ? {
        latitude,
        longitude,
      } : null,
      street_address_line_2,
      city,
      state,
      zip,
      square_footage,
      qr_code,
      description,
      parent,
      phone: (phone || '').replace(/[^0-9+]/g, ''),
      children: (children || []).map(c => ({ id: c.id })),

      story_count: story_count !== '' ? story_count : null,
      building_count: building_count !== '' ? building_count : null,
      room_count: room_count !== '' ? room_count : null,
      year_built: year_built !== '' ? year_built : null,
      foundation_type,
      construction_type,
      roof_system,
      roof_installation_year: roof_installation_year ? parseInt(roof_installation_year) : null,
      garage_parking_material,
      garage_parking_installation_year: garage_parking_installation_year ? parseInt(garage_parking_installation_year) : null,
      window_material,
      window_installation_year: window_installation_year ? parseInt(window_installation_year) : null,
      light_ballasts_count: light_ballasts_count !== '' ? light_ballasts_count : null,
      lighting_interior_type,
      lighting_interior_installation_year: lighting_interior_installation_year ? parseInt(lighting_interior_installation_year) : null,
      lighting_exterior_type,
      lighting_exterior_installation_year: lighting_exterior_installation_year ? parseInt(lighting_exterior_installation_year) : null,
      wiring,
      water_provider,
      electric_provider,
      gas_provider,
      heating_provider,
      sewer_provider,
      communication_provider,
      has_sprinklers,
      has_smoke_alarms,
      has_security_system,
      wind_hazard,
      flood_hazard,
      approximate_building_value,
      content_value,
      rent_value,
      total_insured_value,
      building_square_footage: building_square_footage !== '' ? building_square_footage : null,
      lot_square_footage: lot_square_footage !== '' ? lot_square_footage : null,
      office_square_footage: office_square_footage !== '' ? office_square_footage : null,
      retail_square_footage: retail_square_footage !== '' ? retail_square_footage : null,
      rentable_square_footage: rentable_square_footage !== '' ? rentable_square_footage : null,
      common_area_square_footage: common_area_square_footage !== '' ? common_area_square_footage : null,
      garage_parking_square_footage: garage_parking_square_footage !== '' ? garage_parking_square_footage : null,
      misc_square_footage: misc_square_footage !== '' ? misc_square_footage : null,
      vacant_land_acres: vacant_land_acres !== '' ? vacant_land_acres : null,
      pool_count: pool_count !== '' ? pool_count : null,
      hours_of_operation: null,
      remodel_summary,
      remodel_year: remodel_year ? parseInt(remodel_year) : null,
      floor_type_1,
      floor_type_2,
      cabinet_type,
      countertop_type,
      wall_coverings,
      category: categoryId || null,
      category_other: categoryOther,

      archived,
    };

    const to_add_custom_fields = (customFields || []).filter(c => !!c.value);
    const to_remove_custom_field_ids = (removedCustomFields || []).map(f => f.id);

    if (to_add_custom_fields.length) {
      data.to_add_custom_fields = to_add_custom_fields;
    }

    if (to_remove_custom_field_ids.length) {
      data.to_remove_custom_field_ids = to_remove_custom_field_ids;
    }

    if (hours_of_operation) {
      data.hours_of_operation = hours_of_operation;
    }

    if (equipmentAdded && equipmentAdded.length) {
      data.equipment_added = [...new Set(equipmentAdded)];
    }

    if (partsAdded && partsAdded.length) {
      data.parts_added = [...new Set(partsAdded)];
    }

    if (equipmentRemoved && equipmentRemoved.length) {
      data.equipment_removed = [...new Set(equipmentRemoved)];
    }

    if (partsRemoved && partsRemoved.length) {
      data.parts_removed = [...new Set(partsRemoved)];
    }

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    data.image = image ? image.id : null;
    if (images) {
      data.images = images.map(f => f.id);
    }

    return this._patch(`company/locations/${id}/`, data).then(e => PropertyLocationModel.factory(e));
  }

  archiveLocation(id) {
    return this._patch(`company/locations/${id}/`, { archived: true }).then(e => PropertyLocationModel.factory(e));
  }

  setQRCodeLocation(id, qr_code) {
    return this._patch(`company/locations/${id}/`, { qr_code });
  }

  getLocation(id) {
    return this._get(`company/locations/${id}/`).then(e => PropertyLocationModel.factory(e));
  }

  getLocationForRelatedObject(id) {
    return this._get(`company/locations/${id}/related_object_ui/`).then(e => PropertyLocationModel.factory(e));
  }

  getLocationRelatedItems(id) {
    return this._get(`company/locations/${id}/related_items/count/`).then(c => new LocationRelatedItemsModel(c));
  }

  getEquipment(id) {
    return this._get(`company/equipments/${id}/`).then(e => new CompanyEquipmentModel(e));
  }

  getEquipmentForRelatedObject(id) {
    return this._get(`company/equipments/${id}/related_object_ui/`).then(e => new CompanyEquipmentModel(e));
  }

  getEquipmentLogs(equipment_id, limit = 50, offset = 0) {
    const params = {
      limit,
      offset,
    };

    return this._get(`company/equipments/${equipment_id}/logs/`, params).then(m => new ListResponseModel(m, CompanyEquipmentLogModel));
  }

  getEquipmentTypeahead(search = '', field = 'name') {
    const params = {
      search,
    };
    return this._get(`company/equipments/${field}/typeahead/`, params);
  }

  getPart(id) {
    return this._get(`company/parts/${id}/`).then(e => new PartModel(e));
  }

  getPartForRelatedObject(id) {
    return this._get(`company/parts/${id}/related_object_ui/`).then(e => new PartModel(e));
  }

  getPartTypeahead(search = '', field = 'name') {
    const params = {
      search,
    };
    return this._get(`company/parts/${field}/typeahead/`, params);
  }

  getTask(id) {
    return this._get(`company/tasks/${id}/`).then(e => new TaskModel(e));
  }

  getEquipmentPdf(type, ids) {
    return this._get(`company/equipments/qr_code_pdf/${type}/`, {
      id__in: ids.join(','),
    });
  }

  getSharedEquipmentListParams({
    search = '',
    ordering = 'name',
    location = 0,
    status = '',
    rfp = 0,
    archived = false,
    equipment_type = '',
    tags = null,
    location__ancestor_hierarchy__ancestor_id = null,
    exclude_location = null,
  }) {
    const params = {
      search,
      ordering,
      archived,
    };

    if (tags && tags.length) {
      params.tags__or = tags.map(t => t.id).join(',');
    }

    if (location__ancestor_hierarchy__ancestor_id) {
      params.location__ancestor_hierarchy__ancestor_id = location__ancestor_hierarchy__ancestor_id;
    } else if (location) {
      params.location = location;
    }

    if (exclude_location) {
      params.location_id__not = exclude_location;
    }

    if (rfp) {
      params.rfp__id = rfp;
    }

    if (status) {
      params.status = status;
    }

    applyEquipmentTypeFilterToApiParams(params, equipment_type);

    return params;
  }

  getCompanyEquipments({
    search = '',
    ordering = 'name',
    format = '',
    limit = 50,
    offset = 0,
    location = 0,
    status = '',
    rfp = 0,
    archived = false,
    equipment_type = '',
    tags = null,
    location__ancestor_hierarchy__ancestor_id = null,
    exclude_location = null,
    forSelectUi = false,
    hasQrCode = null,
  }) {
    const params = this.getSharedEquipmentListParams({
      search,
      ordering,
      location,
      status,
      rfp,
      archived,
      equipment_type,
      tags,
      location__ancestor_hierarchy__ancestor_id,
      exclude_location,
    });

    params.limit = limit;
    params.offset = offset;

    if (hasQrCode !== null) {
      params.qr_code_object_mapping__code__isnull = !hasQrCode;
    }

    if (format) {
      params.format = format;
    }

    let url = 'company/equipments/';
    if (forSelectUi) {
      url += 'select_ui/';
    }
    if (format) {
      params.format = format;
      if (format == 'csv') {
        url = 'company/equipments_csv/';
      }
    }

    const promise = this._get(url, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, CompanyEquipmentModel));
  }

  getCompanyEquipmentAdditionalFields() {
    return this._get('company/equipments/list_ui/additional_fields/').then(i => ({
      custom_fields: new ListResponseModel(i.custom_fields, CustomFieldModel),
      category_specific_fields: new ListResponseModel(i.category_specific_fields, EquipmentFieldModel),
    }));
  }

  getCompanyEquipmentCategory(id) {
    return this._get(`equipment_categories/${id}/`).then(c => new CompanyEquipmentCategoryModel(c));
  }

  getCompanyEquipmentsCsvExport({
    search = '',
    ordering = 'name',
    location = 0,
    status = '',
    rfp = 0,
    archived = false,
    equipment_type = '',
    tags = null,
    location__ancestor_hierarchy__ancestor_id = null,
    exclude_location = null,
    fields = null,
  }) {
    const params = this.getSharedEquipmentListParams({
      search,
      ordering,
      location,
      status,
      rfp,
      archived,
      equipment_type,
      tags,
      location__ancestor_hierarchy__ancestor_id,
      exclude_location,
    });

    if (fields) {
      params.fields = fields.join(',');
    }

    return this._get('company/equipments/csv_export/', params);
  }

  getRFPEquipmentsCSV(rfp, ordering = 'name') {
    const params = {
      ordering,
    };

    return this._get(`company/rfps/${rfp}/equipments_csv/`, params);
  }

  createCompanyEquipment({
    name,
    manufacturer,
    location,

    image,
    images, // [FileModel,...]
    user_manual_files, // [FileModel,...]
    warranty_files, // [FileModel,...]
    certificate_files, // [FileModel,...]
    equipment_files, // [FileModel,...]
    existing_service_contract_files, // [FileModel,...]
    contract_expiration_date,
    useful_years,
    replacement_year,
    purchase_cost,
    purchase_date,

    make,
    model,
    manufacturer_other,
    serial_number,
    manufacture_date,
    category,
    equipment_category_other,
    equipment_info,
    status,
    status_details,
    current_run_hours,
    current_run_hours_as_of,
    estimated_operating_hours_per_year,
    electrical_info,
    building,
    floor,
    room_area,
    physical_location,
    condition,
    purchase_condition,
    date_of_startup,
    currently_under_warranty,
    warranty_expiration_date,
    name_of_current_service_provider,
    type_of_service_contract,
    replacement_cost,
    routine_maintenance_cost,
    routine_maintenance_frequency,
    is_maintained,
    qr_code,
    category_specific_field_values,
  }, tags, customFields) {
    // required fields
    const data = {
      name,
      manufacturer,
      location,
      model,
      contract_expiration_date: contract_expiration_date || null,
      useful_years: useful_years || null,
      replacement_year: replacement_year || null,
      purchase_cost: purchase_cost || null,
      purchase_date: purchase_date || null,
      replacement_cost: replacement_cost || null,
      routine_maintenance_cost: routine_maintenance_cost || null,
      routine_maintenance_frequency: routine_maintenance_frequency || null,
      is_maintained: is_maintained || false,
      tags: tags || [],
      to_add_custom_fields: (customFields || []).filter(c => !!c.value),
      category_specific_field_values: category_specific_field_values || [],
    };

    if (make) {
      data.make = make;
    }

    // everything else is optional
    data.image = image ? image.id : null;
    if (images) {
      data.images = images.map(f => f.id);
    }
    if (user_manual_files) {
      data.user_manual_files = user_manual_files.map(f => f.id);
    }
    if (warranty_files) {
      data.warranty_files = warranty_files.map(f => f.id);
    }
    if (certificate_files) {
      data.certificate_files = certificate_files.map(f => f.id);
    }
    if (equipment_files) {
      data.equipment_files = equipment_files.map(f => f.id);
    }
    if (existing_service_contract_files) {
      data.existing_service_contract_files = existing_service_contract_files.map(f => f.id);
    }

    if (data.manufacturer === 'other') {
      data.manufacturer_other = manufacturer_other || '';
      data.manufacturer = null;
    } else {
      data.manufacturer_other = '';
    }

    if (serial_number) {
      data.serial_number = serial_number;
    }
    if (manufacture_date) {
      data.manufacture_date = manufacture_date;
    }
    if (category) {
      data.category = category;
    }
    if (equipment_category_other) {
      data.equipment_category_other = equipment_category_other;
    }
    if (equipment_info) {
      data.equipment_info = equipment_info;
    }
    if (status) {
      data.status = status;
    }
    if (status_details) {
      data.status_details = status_details;
    }
    if (current_run_hours) {
      data.current_run_hours = current_run_hours;
    }
    if (current_run_hours_as_of) {
      data.current_run_hours_as_of = current_run_hours_as_of;
    }
    if (estimated_operating_hours_per_year) {
      data.estimated_operating_hours_per_year = estimated_operating_hours_per_year;
    }
    if (electrical_info) {
      data.electrical_info = electrical_info;
    }
    if (building) {
      data.building = building;
    }
    if (floor) {
      data.floor = floor;
    }
    if (room_area) {
      data.room_area = room_area;
    }
    if (physical_location) {
      data.physical_location = physical_location;
    }
    if (condition) {
      data.condition = condition;
    }
    if (purchase_condition) {
      data.purchase_condition = purchase_condition;
    }
    if (date_of_startup) {
      data.date_of_startup = date_of_startup;
    }
    if (currently_under_warranty) {
      data.currently_under_warranty = currently_under_warranty;
    }
    if (warranty_expiration_date) {
      data.warranty_expiration_date = warranty_expiration_date;
    }
    if (name_of_current_service_provider) {
      data.name_of_current_service_provider = name_of_current_service_provider;
    }
    if (type_of_service_contract) {
      data.type_of_service_contract = type_of_service_contract;
    }

    if (qr_code) {
      data.qr_code = qr_code;
    }

    return this._post('company/equipments/', data).then(e => new CompanyEquipmentModel(e));
  }

  updateEquipmentStatus(id, status, status_details) {
    const data = {
      status,
      status_details: status_details || null,
    };
    return this._patch(`company/equipments/${id}/`, data);
  }

  editCompanyEquipment(id, {
    name,
    manufacturer,
    location,

    image,
    images, // [FileModel,...]
    user_manual_files, // [FileModel,...]
    warranty_files, // [FileModel,...]
    certificate_files, // [FileModel,...]
    equipment_files, // [FileModel,...]
    existing_service_contract_files, // [FileModel,...]
    contract_expiration_date,
    useful_years,
    replacement_year,
    purchase_cost,
    purchase_date,

    make,
    model,
    manufacturer_other,
    serial_number,
    manufacture_date,
    category,
    equipment_category_other,
    equipment_info,
    status,
    status_details,
    current_run_hours,
    current_run_hours_as_of,
    estimated_operating_hours_per_year,
    electrical_info,
    building,
    floor,
    room_area,
    physical_location,
    condition,
    purchase_condition,
    date_of_startup,
    currently_under_warranty,
    warranty_expiration_date,
    name_of_current_service_provider,
    type_of_service_contract,
    qr_code,
    purchased_from,
    installed_by,
    replacement_cost,
    routine_maintenance_cost,
    routine_maintenance_frequency,
    is_maintained,
    category_specific_field_values,
  }, tags, customFields, removedFields) {
    // required fields
    const data = {
      name,
      manufacturer,
      serial_number,
      location,
      model,
      purchased_from,
      installed_by,
      tags: tags || [],
      to_add_custom_fields: (customFields || []).filter(c => !!c.value),
      to_remove_custom_field_ids: (removedFields || []).map(f => f.id),
      category_specific_field_values: category_specific_field_values || [],
      contract_expiration_date: contract_expiration_date || null,
      useful_years: useful_years || null,
      replacement_year: replacement_year || null,
      purchase_cost: purchase_cost || null,
      purchase_date: purchase_date || null,
      make: make || null,
      manufacture_date: manufacture_date || null,
      category: category || null,
      equipment_category_other: equipment_category_other || null,
      equipment_info: equipment_info || null,
      status: status || null,
      status_details: status_details || null,
      current_run_hours: current_run_hours || null,
      current_run_hours_as_of: current_run_hours_as_of || null,
      estimated_operating_hours_per_year: estimated_operating_hours_per_year || null,
      electrical_info: electrical_info || null,
      building: building || null,
      floor: floor || null,
      room_area: room_area || null,
      physical_location: physical_location || null,
      condition: condition || null,
      purchase_condition: purchase_condition || null,
      date_of_startup: date_of_startup || null,
      currently_under_warranty: currently_under_warranty || null,
      warranty_expiration_date: warranty_expiration_date || null,
      name_of_current_service_provider: name_of_current_service_provider || null,
      type_of_service_contract: type_of_service_contract || null,
      qr_code: qr_code || null,
      replacement_cost: replacement_cost || null,
      routine_maintenance_cost: routine_maintenance_cost || null,
      routine_maintenance_frequency: routine_maintenance_frequency || null,
      is_maintained: is_maintained || false,
    };

    data.image = image ? image.id : null;
    if (images) {
      data.images = images.map(f => f.id);
    } else {
      data.images = null;
    }

    if (user_manual_files) {
      data.user_manual_files = user_manual_files.map(f => f.id);
    } else {
      data.user_manual_files = null;
    }

    if (warranty_files) {
      data.warranty_files = warranty_files.map(f => f.id);
    } else {
      data.warranty_files = null;
    }

    if (certificate_files) {
      data.certificate_files = certificate_files.map(f => f.id);
    } else {
      data.certificate_files = null;
    }

    if (equipment_files) {
      data.equipment_files = equipment_files.map(f => f.id);
    } else {
      data.equipment_files = null;
    }

    if (existing_service_contract_files) {
      data.existing_service_contract_files = existing_service_contract_files.map(f => f.id);
    } else {
      data.existing_service_contract_files = null;
    }

    if (data.manufacturer === 'other') {
      data.manufacturer_other = manufacturer_other || '';
      data.manufacturer = null;
    } else {
      data.manufacturer_other = '';
    }

    return this._patch(`company/equipments/${id}/`, data).then(e => new CompanyEquipmentModel(e));
  }

  setQRCodeEquipment(id, qr_code) {
    return this._patch(`company/equipments/${id}/`, { qr_code });
  }

  editCompanyEquipmentLocation(id, locationId) {
    return this._patch(`company/equipments/${id}/`, {
      location: locationId,
    });
  }

  batchEditEquipmentLocation(equipmentIds, locationId) {
    const data = {
      equipment_ids: equipmentIds,
      location: locationId,
    };

    return this._post('company/equipments/batch/location/', data);
  }

  archiveEquipment(id) {
    return this._patch(`company/equipments/${id}/`, {
      archived: true,
    });
  }

  batchArchiveEquipment(equipmentIds) {
    const data = {
      equipment_ids: equipmentIds,
      archived: true,
    };
    return this._post('company/equipments/batch/archived/', data);
  }

  // PARTS
  getCompanyParts({
    search = '',
    ordering = 'name',
    format = '',
    limit = 50,
    offset = 0,
    location = 0,
    equipment = '',
    archived = false,
    tags = null,
    location__ancestor_hierarchy__ancestor_id = null,
    exclude_location = null,
    forSelectUi = false,
    returnAll = false,
  }) {
    const params = {
      search,
      ordering,
      archived,
    };

    if (returnAll) {
      params.return_all = true;
    } else {
      params.offset = offset;
      params.limit = limit;
    }

    if (tags && tags.length) {
      params.tags__or = tags.map(t => t.id).join(',');
    }

    if (format) {
      params.format = format;
    }

    if (location__ancestor_hierarchy__ancestor_id) {
      params.location__ancestor_hierarchy__ancestor_id = location__ancestor_hierarchy__ancestor_id;
    } else if (location) {
      params.location = location;
    }

    if (exclude_location) {
      params.location_id__not = exclude_location;
    }

    if (equipment) {
      params.equipment = equipment;
    }

    const url = forSelectUi ? 'company/parts/select_ui/' : 'company/parts/';

    const promise = this._get(url, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, PartModel));
  }

  createCompanyPart({
    name,
    manufacturer,
    location,

    image,
    images, // [FileModel,...]
    equipment_files, // [FileModel,...]

    manufacturer_other,
    part_number,
    equipments_requiring_part, // [EquipmentModel,...]
    description,
    quantity_on_hand,
    target_quantity,
    purchase_cost,

    building,
    floor,
    room_area,
    qr_code,
  }, tags) {
    // required fields
    const data = {
      name,
      manufacturer,
      location,
      tags: tags || [],
      purchase_cost: purchase_cost || null,
    };

    // everything else is optional
    data.image = image ? image.id : null;
    if (images) {
      data.images = images.map(f => f.id);
    }
    if (equipment_files) {
      data.files = equipment_files.map(f => f.id);
    }

    if (data.manufacturer === 'other') {
      data.manufacturer_other = manufacturer_other || '';
      data.manufacturer = null;
    } else {
      data.manufacturer_other = '';
    }

    if (part_number) {
      data.part_number = part_number;
    }
    if (equipments_requiring_part) {
      data.equipments_requiring_part = equipments_requiring_part.map(e => e.id);
    }
    if (description) {
      data.description = description;
    }
    if (quantity_on_hand) {
      data.quantity_on_hand = quantity_on_hand;
    }
    if (target_quantity) {
      data.target_quantity = target_quantity;
    }
    if (building) {
      data.building = building;
    }
    if (floor) {
      data.floor = floor;
    }
    if (room_area) {
      data.room_area = room_area;
    }

    if (qr_code) {
      data.qr_code = qr_code;
    }

    return this._post('company/parts/', data).then(e => new PartModel(e));
  }

  editCompanyPart(id, {
    name,
    manufacturer,
    location,

    image,
    images, // [FileModel,...]
    equipment_files, // [FileModel,...]

    manufacturer_other,
    part_number,
    equipments_requiring_part, // [1,2,3]
    description,
    quantity_on_hand,
    target_quantity,
    purchase_cost,

    building,
    floor,
    room_area,
    qr_code,
  }, tags) {
    // required fields
    const data = {
      name,
      manufacturer,
      location,
      tags: tags || [],
      purchase_cost: purchase_cost || null,
    };

    // everything else is optional
    data.image = image ? image.id : null;
    if (images) {
      data.images = images.map(f => f.id);
    }
    if (equipment_files) {
      data.files = equipment_files.map(f => f.id);
    }

    if (data.manufacturer === 'other') {
      data.manufacturer_other = manufacturer_other || '';
      data.manufacturer = null;
    } else {
      data.manufacturer_other = '';
    }

    if (part_number) {
      data.part_number = part_number;
    }
    if (equipments_requiring_part) {
      data.equipments_requiring_part = equipments_requiring_part.map(e => e.id);
    }
    if (description) {
      data.description = description;
    }
    if (quantity_on_hand) {
      data.quantity_on_hand = quantity_on_hand;
    }
    if (target_quantity) {
      data.target_quantity = target_quantity;
    }
    if (building) {
      data.building = building;
    }
    if (floor) {
      data.floor = floor;
    }
    if (room_area) {
      data.room_area = room_area;
    }

    if (qr_code) {
      data.qr_code = qr_code;
    }

    return this._patch(`company/parts/${id}/`, data).then(e => new PartModel(e));
  }

  setQRCodePart(id, qr_code) {
    return this._patch(`company/parts/${id}/`, { qr_code });
  }

  setPartEquipments(id, equipments_requiring_part) {
    // required fields
    const data = {
      equipments_requiring_part: (equipments_requiring_part || []).map(e => e.id),
    };

    return this._patch(`company/parts/${id}/`, data).then(e => new PartModel(e));
  }

  getEquipmentServiceReports(equipment_id) {
    const params = {
      limit: 5000,
      offset: 0,
    };

    return this._get(`company/equipments/${equipment_id}/service_dates/`, params).then(m => new ListResponseModel(m, ServiceReportLogModel));
  }

  addEquipmentServiceReport(equipment_id, model) {
    return this._post(`company/equipments/${equipment_id}/service_dates/`, {
      service_date: model.service_date,
      service_completed_by: model.service_completed_by,
      service_description: model.service_description,
      service_report_files: (model.service_report_files || []).map(f => f.id),
      service_cost: model.service_cost || null,
    });
  }

  editEquipmentServiceReport(equipment_id, id, model) {
    return this._patch(`company/equipments/${equipment_id}/service_dates/${id}/`, {
      service_date: model.service_date,
      service_completed_by: model.service_completed_by,
      service_description: model.service_description,
      service_report_files: (model.service_report_files || []).map(f => f.id),
      service_cost: model.service_cost || null,
    });
  }

  deleteEquipmentServiceReport(equipment_id, id) {
    return this._delete(`company/equipments/${equipment_id}/service_dates/${id}/`);
  }

  getCompanyRoles(search = '', companyIdOverride = null) {
    const params = search ? { search } : {};
    return this._get('company/roles/', params, { companyIdOverride }).then(response => ({
      roles: (response.roles || []).map(r => new CompanyRoleModel(r)),
      permissions: (response.permissions || []).map(p => new CompanyPermissionModel(p)),
    }));
  }

  getCompanyPermissions() {
    return this._get('company/permissions/'); // permissions: ['','',...]
  }

  addCompanyRole({ display_name, description, permissions }) {
    const data = {
      display_name,
      description,
      permissions,
    };

    return this._post('company/roles/', data).then(r => new CompanyRoleModel(r));
  }

  editCompanyRole(id, { display_name, description, permissions }) {
    const data = {
      display_name,
      description,
      permissions,
    };

    return this._patch(`company/roles/${id}/`, data).then(r => new CompanyRoleModel(r));
  }

  deleteCompanyRole(id) {
    return this._delete(`company/roles/${id}/`);
  }

  getPaymentMethods(ordering = 'name', limit = 50, offset = 0) {
    return this._get('payments/payment_methods/', {
      ordering,
      limit,
      offset,
    }).then(m => new ListResponseModel(m, PaymentMethodModel));
  }

  removePaymentMethod(id) {
    return this._delete(`payments/payment_methods/${id}/`);
  }

  setDefaultPaymentMethod(default_payment_method) {
    return this._patch('company/', {
      default_payment_method,
    });
  }

  submitPayment(rfpId, invoiceId, paymentMethodId) {
    return this._post(`company/rfps/${rfpId}/invoices/${invoiceId}/pay/`, {
      payment_method: paymentMethodId,
    });
  }

  markAsPaid(rfpId, invoiceId, payment_date) {
    return this._post(`company/rfps/${rfpId}/invoices/${invoiceId}/offline_payment_received/`, {
      payment_date,
    });
  }

  getCompanyStripeAccount() {
    return this._get('payments/stripe_account/').then(response => new StripeAccountModel(response)).catch((error) => {
      if (error.status == 404) {
        return {
          charges_enabled: false,
          details_submitted: false,
          payouts_enabled: false,
        };
      }
      throw (error);
    });
  }

  getCompanyStripeOnboardingLink() {
    return this._post('payments/stripe_account/link/').then(response => response.link);
  }

  addCompanyBankAccount(token) {
    return this._post('payments/payment_methods/', {
      type: 'ach_debit_bank_account',
      stripe_token_id: token,
    }).then(res => new PaymentMethodModel(res));
  }

  verifyBankAccount(id, amount_1_cents, amount_2_cents) {
    return this._post(`payments/payment_methods/${id}/verify/`, {
      amount_1_cents, // test w/ 32, 45
      amount_2_cents,
    });
  }

  addCompanyCreditCard(paymentMethodId) {
    return this._post('payments/payment_methods/', {
      type: 'card',
      stripe_payment_method_id: paymentMethodId,
    }).then(res => new PaymentMethodModel(res));
  }

  inviteToCompany({
    email,
    first_name,
    last_name,
    job_title,
    role,
    companyIdOverride = null,
    custom_permissions = null,
    restricted_locations,
    phone,
    phone_ext,
    mobile_phone,
    source = 'user_invitation',
  }) {
    const data = {
      email,
      first_name,
      last_name,
      role,
      custom_permissions,
      source,
    };

    if (restricted_locations) {
      data.restricted_locations = restricted_locations;
    }

    if (job_title) {
      data.job_title = job_title;
    }

    if (phone) {
      data.phone = phone.replace(/[^0-9+]/g, '');
    }

    if (phone_ext) {
      data.phone_ext = phone_ext;
    }

    if (mobile_phone) {
      data.mobile_phone = mobile_phone.replace(/[^0-9+]/g, '');
    }

    return this._post('company/invites/', data, { companyIdOverride }).then((res) => {
      if (res && res.user) {
        res.user = new UserModel(res.user);
      }

      return res;
    });
  }

  bulkInviteToCompany({ emails, role, restricted_locations = [], custom_permissions = [] }) {
    const data = {
      emails,
      role,
      restricted_locations,
      custom_permissions,
    };

    return this._post('company/invites/batch/', data).then(r => r);
  }

  removeUser(id) {
    return this._delete(`company/users/${id}/remove/`);
  }

  editCompanyUser(id, data) {
    return this._patch(`company/users/${id}/`, data);
  }

  getRFP(id) {
    return this._get(`company/rfps/${id}/`).then(res => new RFPModel(res));
  }

  getRFPByRefNumber(id) {
    if (Vue.prototype.$globals.selectedDashboard === 'sp') {
      return this._get(`provider/rfps/r/${id}/`).then(res => new RFPModel(res));
    } if (Vue.prototype.$globals.selectedDashboard === 'eo') {
      return this._get(`company/rfps/r/${id}/`).then(res => new RFPModel(res));
    }
    return this._get(`helix_admin/rfps/r/${id}/`).then(res => new RFPModel(res));
  }

  getProviderInactiveQuoteRequestedRfps({
    search = '',
    ordering = '-created',
    limit = 50,
    offset = 0,
    company = 0,
    type,
    date_matched_before,
    date_matched_after,
    matched_service_provider_id = 0,
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    if (company) {
      params.rfp__company = company;
    }

    if (type) {
      params.rfp__type = type;
    }

    if (date_matched_before) {
      params.created_before = date_matched_before;
    }

    if (date_matched_after) {
      params.created_after = date_matched_after;
    }

    if (matched_service_provider_id) {
      params.rfp__matched_service_provider = matched_service_provider_id;
    }

    return this._get('provider/inactive_quote_requested_rfps/', params).then(m => new ListResponseModel(m, RFPQuoteRequestModel));
  }

  getCompanyRFPs({
    search = '',
    ordering = 'name',
    format = '',
    limit = 50,
    offset = 0,
    equipment = 0,
    part = 0,
    status = '',
    status_not = '',
    status_in = '',
    date_submitted_before,
    date_submitted_after,
    matched_service_provider = 0,
    company = 0,
    type,
    archived = false,
    quote_accepted_date,
    my_pending_quote_count__gt = null,
    quote_requested_companies__company_id = null,
    forSelectUi = false,
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    if (archived !== null) {
      if (Vue.prototype.$globals.selectedDashboard === 'sp') {
        params.archived_by_sp = archived;
      } else {
        params.archived = archived;
      }
    }

    if (equipment) {
      params.equipment_ids = JSON.stringify([equipment]);
    }

    if (part) {
      params.part_ids = JSON.stringify([part]);
    }

    if (status && status === 'quoted') {
      params.status = 'matched';
      if (Vue.prototype.$globals.selectedDashboard === 'sp') {
        params.my_sent_quote_count__gt = 0;
      } else {
        params.quote_received_company_count__gt = 0;
      }
    } else if (status && status === 'matched') {
      params.status = 'matched';
      if (Vue.prototype.$globals.selectedDashboard === 'sp') {
        params.my_sent_quote_count__lt = 1;
      } else {
        params.quote_received_company_count__lt = 1;
      }
    } else if (status) {
      params.status = status;
    }

    if (status_not) {
      params.status__not = status_not;
    }

    if (status_in) {
      params.status__in = status_in;
    }

    if (format) {
      params.format = format;
    }

    if (date_submitted_before) {
      params.date_submitted_before = date_submitted_before;
    }
    if (date_submitted_after) {
      params.date_submitted_after = date_submitted_after;
    }

    if (quote_accepted_date) {
      params.quote_accepted_date_after = quote_accepted_date;
    }

    if (matched_service_provider) {
      params.matched_service_provider = matched_service_provider;
    }

    if (company) {
      params.company = company;
    }

    if (type) {
      params.type = type;
    }

    if (my_pending_quote_count__gt !== null) {
      params.my_pending_quote_count__gt = my_pending_quote_count__gt;
    }

    if (quote_requested_companies__company_id) {
      params.quote_requested_companies__company_id = quote_requested_companies__company_id;
    }

    const dashboardUrlMap = {
      eo: 'company',
      sp: 'provider',
      owner: 'helix_admin',
    };

    const baseUrl = dashboardUrlMap[Vue.prototype.$globals.selectedDashboard];

    const url = forSelectUi ? 'rfps/select_ui/' : 'rfps/';

    const promise = this._get(`${baseUrl}/${url}`, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, RFPModel));
  }

  getProviderDashboardRFPs({
    ordering = 'name',
    limit = 5,
    status = '',
    status_not = '',
  }) {
    const params = {
      ordering,
      limit,
    };

    if (status) {
      params.status = status;
    }

    if (status_not) {
      params.status__not = status_not;
    }

    return this._get('provider/dashboard/rfps/', params).then(rfps => new ListResponseModel(rfps, RFPModel));
  }

  updateSPRFPAssignees(id, service_provider_assignees) {
    return this._patch(`provider/rfps/${id}/`, {
      service_provider_assignees: (service_provider_assignees || []).map(a => a.id),
    });
  }

  createCompanyRFP({
    // required
    name,

    // optional
    location,
    installation_requested = false,
    type,
    service_agreement_type,
    priority,
    proposal_type,

    preferred_vendor,
    preferred_vendor_other,

    new_equipment_category,
    new_equipment_description,
    replace_existing_unit,

    energy_assessment_category,
    statement_to_energy_assessment_provider,
    energy_savings_category,
    statement_to_energy_savings_provider,
    rental_equipment_category,
    length_of_rental_equipment_term,

    assets, // [1,2,3]
    parts, // [{id,quantity}]
    task,
    eo_requested_vendors, // [1,2,3]

    estimated_run_hours_per_year,
    length_of_service_agreement_term_years,
    quote_due_date,
    project_timeline,
    additional_comments,

    attachments, // [FileModel,...]
    point_of_contact,
  }, status = 'draft') {
    // required fields
    const data = {
      name,
      status,
      installation_requested,
    };

    if (location) {
      data.location = location;
    }

    if (type) {
      data.type = type;
    }

    if (service_agreement_type) {
      data.service_agreement_type = service_agreement_type;
    }

    if (priority) {
      data.priority = priority;
    }

    if (proposal_type) {
      data.proposal_type = proposal_type;
    }

    if (preferred_vendor) {
      data.preferred_vendor = preferred_vendor;
    }

    if (preferred_vendor_other) {
      data.preferred_vendor_other = preferred_vendor_other;
      data.preferred_vendor = null;
    }

    if (new_equipment_category) {
      data.new_equipment_category = new_equipment_category;
    }

    if (new_equipment_description) {
      data.new_equipment_description = new_equipment_description;
    }

    if (replace_existing_unit) {
      data.replace_existing_unit = replace_existing_unit;
    }

    if (energy_assessment_category) {
      data.energy_assessment_category = energy_assessment_category;
    }

    if (statement_to_energy_assessment_provider) {
      data.statement_to_energy_assessment_provider = statement_to_energy_assessment_provider;
    }

    if (energy_savings_category) {
      data.energy_savings_category = energy_savings_category;
    }

    if (statement_to_energy_savings_provider) {
      data.statement_to_energy_savings_provider = statement_to_energy_savings_provider;
    }

    if (rental_equipment_category) {
      data.rental_equipment_category = rental_equipment_category;
    }

    if (length_of_rental_equipment_term) {
      data.length_of_rental_equipment_term = length_of_rental_equipment_term;
    }

    if (assets) {
      data.assets = assets.map(a => a.id);
    }

    if (eo_requested_vendors) {
      data.eo_requested_vendors = eo_requested_vendors.map(c => c.id);
    }

    if (parts) {
      data.parts = parts.map(p => ({
        part: p.id,
        quantity: p.quantity || 1,
      }));
    }

    if (task) {
      data.task = task.id;
    }

    if (estimated_run_hours_per_year) {
      data.estimated_run_hours_per_year = estimated_run_hours_per_year;
    }

    if (length_of_service_agreement_term_years) {
      data.length_of_service_agreement_term_years = length_of_service_agreement_term_years;
    }

    if (quote_due_date) {
      data.quote_due_date = quote_due_date;
    }

    if (project_timeline) {
      data.project_timeline = project_timeline;
    }

    if (additional_comments) {
      data.additional_comments = additional_comments;
    }

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    if (point_of_contact) {
      data.point_of_contact = point_of_contact;
    }

    return this._post('company/rfps/', data).then(e => new RFPModel(e));
  }

  editCompanyRFP(id, {
    // required
    name,

    // optional
    location,
    installation_requested = false,
    type,
    service_agreement_type,
    priority,
    proposal_type,

    preferred_vendor,
    preferred_vendor_other,

    new_equipment_category,
    new_equipment_description,
    replace_existing_unit,
    energy_assessment_category,
    statement_to_energy_assessment_provider,
    energy_savings_category,
    statement_to_energy_savings_provider,
    rental_equipment_category,
    length_of_rental_equipment_term,

    assets, // [1,2,3]
    parts, // [{id,quantity}]
    eo_requested_vendors, // [1,2,3]

    estimated_run_hours_per_year,
    length_of_service_agreement_term_years,
    quote_due_date,
    project_timeline,
    additional_comments,

    attachments, // [FileModel,...]
    point_of_contact,
  }, companyIdOverride = null) {
    // required fields
    const data = {
      name,
      installation_requested,
    };

    if (location !== undefined) {
      data.location = location;
    }

    if (type) {
      data.type = type;
    }

    if (service_agreement_type) {
      data.service_agreement_type = service_agreement_type;
    }

    if (priority) {
      data.priority = priority;
    }

    if (proposal_type) {
      data.proposal_type = proposal_type;
    }

    if (preferred_vendor) {
      data.preferred_vendor = preferred_vendor;
    }

    if (preferred_vendor_other) {
      data.preferred_vendor_other = preferred_vendor_other;
      data.preferred_vendor = null;
    }

    if (new_equipment_category) {
      data.new_equipment_category = new_equipment_category;
    }

    if (new_equipment_description) {
      data.new_equipment_description = new_equipment_description;
    }

    if (replace_existing_unit) {
      data.replace_existing_unit = replace_existing_unit;
    }

    if (energy_assessment_category) {
      data.energy_assessment_category = energy_assessment_category;
    }

    if (statement_to_energy_assessment_provider) {
      data.statement_to_energy_assessment_provider = statement_to_energy_assessment_provider;
    }

    if (energy_savings_category) {
      data.energy_savings_category = energy_savings_category;
    }

    if (statement_to_energy_savings_provider) {
      data.statement_to_energy_savings_provider = statement_to_energy_savings_provider;
    }

    if (rental_equipment_category) {
      data.rental_equipment_category = rental_equipment_category;
    }

    if (length_of_rental_equipment_term) {
      data.length_of_rental_equipment_term = length_of_rental_equipment_term;
    }

    if (assets) {
      data.assets = assets.map(a => a.id);
    }

    if (eo_requested_vendors) {
      data.eo_requested_vendors = eo_requested_vendors.map(c => c.id);
    }

    if (quote_due_date) {
      data.quote_due_date = quote_due_date;
    }

    if (parts) {
      data.parts = parts.map(p => ({
        part: p.id,
        quantity: p.quantity || 0,
      }));
    }

    if (estimated_run_hours_per_year) {
      data.estimated_run_hours_per_year = estimated_run_hours_per_year;
    }

    if (length_of_service_agreement_term_years) {
      data.length_of_service_agreement_term_years = length_of_service_agreement_term_years;
    }

    if (project_timeline) {
      data.project_timeline = project_timeline;
    }

    if (additional_comments) {
      data.additional_comments = additional_comments;
    }

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    if (point_of_contact) {
      data.point_of_contact = point_of_contact;
    }

    return this._patch(`${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin' : 'company'}/rfps/${id}/`, data, {
      companyIdOverride,
    }).then(e => new RFPModel(e));
  }

  editRFPAttachmentsAsCompany(id, attachments, companyIdOverride = null) {
    const data = {};

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    return this._patch(`${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin' : 'company'}/rfps/${id}/`, data, {
      companyIdOverride,
    }).then(e => new RFPModel(e));
  }

  editRFPAttachments(id, attachments, fromOwner) {
    return this._patch(`company/rfps/${id}/attachments/`, {
      attachments: attachments.map(f => f.id),
    }, { fromOwner }).then(e => new RFPModel(e));
  }

  editRFPStatus(id, status) {
    return this._patch(`${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin' : 'company'}/rfps/${id}/`, {
      status,
    }).then(e => new RFPModel(e));
  }

  editRFPPointOfContact(id, point_of_contact) {
    return this._patch(`${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin' : 'company'}/rfps/${id}/`, {
      point_of_contact,
    }).then(e => new RFPModel(e));
  }

  archiveRFP(id, fromSP) {
    let path = `${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin' : 'company'}/rfps/${id}/`;

    const data = {};

    if (fromSP) {
      data.archived_by_sp = true;
      path = `company/rfps/${id}/provider_view/`;
    } else {
      data.archived = true;
    }

    return this._patch(path, data).then(e => new RFPModel(e));
  }

  completeRFP(id) {
    return this._post(`company/rfps/${id}/complete/`);
  }

  getCompanyQuotes(viewingSent, search = '', ordering = 'name', format = '', limit = 50, offset = 0, equipment = 0, part = 0, status = '', date_submitted_before = null, date_submitted_after = null, matched_user = null) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    if (equipment) {
      params.equipment = equipment;
    }

    if (part) {
      params.part = part;
    }

    if (status) {
      params.status = status;
    }

    if (format) {
      params.format = format;
    }

    if (date_submitted_before) {
      params.created_before = date_submitted_before;
    }
    if (date_submitted_after) {
      params.created_after = date_submitted_after;
    }

    if (matched_user) {
      if (Vue.prototype.$globals.selectedDashboard === 'eo') {
        params.provider = matched_user;
      } else {
        params.customer = matched_user;
      }
    }

    const path = viewingSent ? 'provider/quotes_sent/' : 'company/quotes_received/';
    const promise = this._get(path, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, QuoteModel));
  }

  getCompanyTransactions(search = '', ordering = 'name', format = '', limit = 50, offset = 0, equipment = 0, part = 0, status = '', date_submitted_before = null, date_submitted_after = null, matched_user = null) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    if (equipment) {
      params.equipment = equipment;
    }

    if (part) {
      params.part = part;
    }

    if (status) {
      params.status = status;
    }

    if (format) {
      params.format = format;
    }

    if (date_submitted_before) {
      params.date_submitted_before = date_submitted_before;
    }
    if (date_submitted_after) {
      params.date_submitted_after = date_submitted_after;
    }

    if (matched_user) {
      if (Vue.prototype.$globals.selectedDashboard === 'eo') {
        params.matched_service_provider = matched_user;
      } else {
        params.company = matched_user;
      }
    }

    const path = 'company/transactions/';
    const promise = this._get(path, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, TransactionModel));
  }

  getCompanyInvoices({ viewingSent = false, search = '', ordering = 'name', format = '', limit = 50, offset = 0, status = '', date_submitted_before = 0, date_submitted_after = 0, matched_user = 0, equipment = 0, part = 0 } = {}) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    if (status) {
      if (status === 'canceled') {
        params.canceled = true;
      } else {
        params.canceled = false;
        if (status === 'paid') {
          params.paid = true;
        } else {
          params.charge__status = status;
        }
      }
    }

    if (format) {
      params.format = format;
    }

    if (date_submitted_before) {
      params.created_before = date_submitted_before;
    }
    if (date_submitted_after) {
      params.created_after = date_submitted_after;
    }

    if (matched_user) {
      if (Vue.prototype.$globals.selectedDashboard === 'eo') {
        params.provider = matched_user;
      } else {
        params.customer = matched_user;
      }
    }

    if (equipment) {
      params.equipment = equipment;
    }

    if (part) {
      params.part = part;
    }

    const path = viewingSent ? 'provider/invoices_sent/' : 'company/invoices_received/';
    const promise = this._get(path, params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, InvoiceModel));
  }

  getRFPInvoices({ rfp, limit = 50, offset = 0, ordering = 'name', canceled = null }) {
    return this._get(`company/rfps/${rfp}/invoices/`, {
      limit,
      offset,
      ordering,
      canceled,
    }).then(m => new ListResponseModel(m, InvoiceModel));
  }

  getRFPProposals(rfp, limit = 50, offset = 0) {
    const params = {
      limit,
      offset,
    };

    return this._get(`company/rfps/${rfp}/quotes/`, params).then(m => new ListResponseModel(m, QuoteModel));
  }

  // TASKS
  getCompanyTasks({
    search = '',
    ordering = 'name',
    format = '',
    limit = 50,
    offset = 0,
    equipment = 0,
    part = 0,
    rfp = '',
    location = '',
    completed = null,
    date_submitted_before = null,
    date_submitted_after = null,
    matched_user = null,
    archived = false,
    assignees = null,
    priority = null,
    location__ancestor_hierarchy__ancestor_id = null,
    computed_location__ancestor_hierarchy__ancestor_id = null,
    completed_by = null,
    project = null,
    computed_archived = null,
    assigneeCount = null,
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    // checks both the project and the task being archived
    if (computed_archived !== null) {
      params.computed_archived = computed_archived;
    }

    if (archived !== null) {
      params.archived = archived;
    }

    if (equipment) {
      params.equipment = equipment;
    }

    if (part) {
      params.part = part;
    }

    if (rfp) {
      params.rfp = rfp;
      params.rfp__id = rfp;
    }

    if (location__ancestor_hierarchy__ancestor_id) {
      params.location__ancestor_hierarchy__ancestor_id = location__ancestor_hierarchy__ancestor_id;
    } else if (computed_location__ancestor_hierarchy__ancestor_id) {
      params.computed_location__ancestor_hierarchy__ancestor_id = computed_location__ancestor_hierarchy__ancestor_id;
    } else if (location) {
      params.location = location;
    }

    if (format) {
      params.format = format;
    }

    if (completed !== null) {
      params.current_instance__completed = completed;
    }

    if (priority !== null && priority !== undefined) {
      params.priority = priority;
    }

    if (date_submitted_before) {
      params.current_instance__due_date_before = date_submitted_before;
    }
    if (date_submitted_after) {
      params.current_instance__due_date_after = date_submitted_after;
    }

    if (matched_user) {
      if (Vue.prototype.$globals.selectedDashboard === 'sp') {
        params.rfp__company = matched_user;
      } else {
        params.rfp__matched_service_provider = matched_user;
      }
    }

    if (assignees?.length) {
      params.assignees = Array.isArray(assignees) ? assignees.join(',') : assignees;
    }

    if (completed_by) {
      params.current_instance__completed_by = completed_by;
    }

    if (project) {
      params.project = project;
    }

    if (assigneeCount !== null) {
      params.assignee_count = assigneeCount;
    }

    const promise = this._get('company/tasks/', params);

    if (params.format) {
      return promise; // csv
    }

    return promise.then(m => new ListResponseModel(m, TaskModel));
  }

  getTasksForBatchNotification(id, {
    search = '',
    ordering = 'name',
    limit = 50,
    offset = 0,
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
    };

    return this._get(`company/tasks/notifications_batch_records/${id}/`, params).then(m => new ListResponseModel(m, TaskModel));
  }

  getCompanyTaskStatuses() {
    return this._get('company/tasks/statuses/').then(b => new ListResponseModel(b, TaskStatusModel));
  }

  addCompanyTaskStatus(status) {
    return this._post('company/tasks/statuses/', status)
      .then(s => new TaskStatusModel(s))
      .then(Vue.prototype.$globals.cachedData.getCompanyTaskStatuses(true));
  }

  getCompanyTaskCategories() {
    return this._get('company/tasks/categories/select_ui/').then(cat => (cat));
  }

  getPagedTaskCategories({ search = '', limit = 5000, offset = 0 }) {
    const params = {
      search,
      limit,
      ordering: 'name',
      offset,
    };

    return this._get('company/tasks/categories/paged/', params).then(m => new ListResponseModel(m, OptionModel));
  }

  async addTaskCategory(name) {
    const category = await this._post('company/tasks/categories/', { name });
    const optionModel = new OptionModel(category);
    await Vue.prototype.$globals.cachedData.getCompanyTaskCategories(true);
    return optionModel;
  }

  async editTaskCategory(id, name) {
    const category = await this._patch(`company/tasks/categories/${id}`, { name });
    const optionModel = new OptionModel(category);
    await Vue.prototype.$globals.cachedData.getCompanyTaskCategories(true);
    return optionModel;
  }

  async deleteTaskCategory(id, migrate_to = null) {
    const data = {};
    // if migrate_to is present but null, the API is angry
    if (migrate_to !== null) {
      data.migrate_to = migrate_to;
    }
    await this._post(`company/tasks/categories/${id}/delete/`, data);
    await Vue.prototype.$globals.cachedData.getCompanyTaskCategories(true);
  }

  getCompanyTasksCsvExport({
    taskStatusIds = null,
    taskDueDateAfter = null,
    taskDueDateBefore = null,
    taskCompletedDateAfter = null,
    taskCompletedDateBefore = null,
    include_no_due_date,
    taskProjectArchived = null,
    assignees = [],
  }) {
    const params = {
      include_no_due_date,
    };
    if (taskStatusIds?.length) {
      params.status__in = taskStatusIds.join(',');
    }

    if (taskDueDateBefore) {
      params.due_date_before = taskDueDateBefore;
    }

    if (taskDueDateAfter) {
      params.due_date_after = taskDueDateAfter;
    }

    if (taskCompletedDateBefore) {
      params.completed_date_before = taskCompletedDateBefore;
    }

    if (taskCompletedDateAfter) {
      params.completed_date_after = taskCompletedDateAfter;
    }

    if (taskProjectArchived !== null) {
      params.task__project__archived = taskProjectArchived;
    }

    if (assignees.length) {
      params.assignees = assignees.join(',');
      params.assignee_ignore_checklists = true;
    }

    return this._get('company/tasks/instances/csv_export/', params);
  }

  editCompanyTaskStatus(id, status) {
    const data = { ...status };

    if (!data.archived) {
      delete data.migrated_to_id;
    }

    return this._patch(`company/tasks/statuses/${id}/`, data).then(s => new TaskStatusModel(s));
  }

  getProjectCompanyTasks(project, {
    search = '',
    ordering = 'name',
    limit = 100,
    offset = 0,
    archived = false,
    overdue = false,
    assigneeUserIds = null,
    assigneeIgnoreChecklists = null,
    unassigned = null,
    categoryIds = null,
    priorityIds = null,
    statusIds = null,
    equipment = null,
    part = null,
    sensor = null,
    reservation = null,
    template = null,
    pm_schedule = null,
    rfp = null,
    location = null,
    dueDateAfter = null,
    dueDateBefore = null,
    completedDateAfter = null,
    completedDateBefore = null,
    completed = null,
    computedArchived = null,
    workType = null,
    taskSource = null,
    taskSourceV2 = null,
  }) {
    const params = {
      project,
      search,
      ordering,
      limit,
      offset,
    };

    if (completed !== null) {
      params.current_instance__completed = completed;
    }

    if (archived !== null) {
      params.archived = archived;
    }

    if (computedArchived !== null) {
      params.computed_archived = computedArchived;
    }

    if (overdue !== null) {
      params.overdue = overdue;
    }

    if (assigneeUserIds?.length) {
      params.assignees = Array.isArray(assigneeUserIds) ? assigneeUserIds.join(',') : assigneeUserIds;
    }

    if (assigneeIgnoreChecklists !== null) {
      params.assignee_ignore_checklists = assigneeIgnoreChecklists;
    }

    if (unassigned) {
      params.assignee_count = 0;
    }

    if (categoryIds?.length) {
      params.category__in = categoryIds.join(',');
    }

    if (priorityIds?.length) {
      params.priority__in = priorityIds.join(',');
    }

    if (statusIds?.length) {
      params.current_instance__status__in = statusIds.join(',');
    }

    if (equipment !== null) {
      params.equipment = equipment;
    }

    if (part) {
      params.part = part;
    }

    if (sensor) {
      params.sensor = sensor;
    }

    if (reservation) {
      params.reservation = reservation;
    }

    if (template) {
      params.template = template;
    }

    if (pm_schedule) {
      params.pm_schedule = pm_schedule;
    }

    if (rfp) {
      params.rfp = rfp;
    }

    if (location !== null) {
      params.computed_location__ancestor_hierarchy__ancestor_id = location;
    }

    if (dueDateAfter !== null) {
      params.current_instance__due_date_after = dueDateAfter;
    }

    if (dueDateBefore !== null) {
      params.current_instance__due_date_before = dueDateBefore;
    }

    if (completedDateAfter !== null) {
      params.current_instance__completed_date_after = completedDateAfter;
    }

    if (completedDateBefore !== null) {
      params.current_instance__completed_date_before = completedDateBefore;
    }

    if (workType !== null) {
      params.work_type = workType;
    }

    if (taskSource !== null) {
      params.work_order_request__isnull = taskSource;
    }

    if (taskSourceV2 === WorkType.Task) {
      params.work_order_request__isnull = true;
      params.pm_schedule__isnull = true;
    } else if (taskSourceV2 === WorkType.WorkOrder) {
      params.work_order_request__isnull = false;
    } else if (taskSourceV2 === WorkType.Schedule) {
      params.pm_schedule__isnull = false;
    }

    return this._get('company/tasks/projects_view/', params).then(m => new ListResponseModel(m, TaskModel, { groupListTask: true }));
  }

  getCompanyTaskInstances({
    search = '',
    ordering = 'name',
    limit = 50,
    offset = 0,
    date_submitted_before,
    date_submitted_after,
    completed = null,
    archived = false,
    project,
    location,
    equipment,
    part,
    sensor,
    reservation,
    template,
    pm_schedule,
    rfp,
    task__archived = null,
    task__overdue = null,
    projectArchived = null,
    assigneeUserIds = null,
    unassigned = null,
    category = null,
    priority = null,
    status = null,
    task__location__ancestor_hierarchy__ancestor_id = null,
    task__computed_location__ancestor_hierarchy__ancestor_id = null,
    taskWorkTypeId = null,
    taskSource = null,
    taskSourceV2 = null,
    completed_by = null,
    rfpMatchedUser = null,
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      archived,
    };

    if (completed !== null) {
      params.completed = completed;
    }

    if (date_submitted_before) {
      params.due_date_before = date_submitted_before;
    }

    if (date_submitted_after) {
      params.due_date_after = date_submitted_after;
    }

    if (project) {
      params.task__project = (project.length) ? project.join(',') : project;
    }

    if (task__location__ancestor_hierarchy__ancestor_id) {
      params.task__location__ancestor_hierarchy__ancestor_id = task__location__ancestor_hierarchy__ancestor_id;
    } else if (task__computed_location__ancestor_hierarchy__ancestor_id) {
      params.task__computed_location__ancestor_hierarchy__ancestor_id = task__computed_location__ancestor_hierarchy__ancestor_id;
    } else if (location) {
      if (location.length) {
        params.task__location__in = location.join(',');
      } else {
        params.task__location = location;
      }
    }

    if (equipment) {
      if (equipment.length) {
        params.task__equipment__in = equipment.join(',');
      } else {
        params.task__equipment = equipment;
      }
    }

    if (part) {
      if (part.length) {
        params.task__part__in = part.join(',');
      } else {
        params.task__part = part;
      }
    }

    if (sensor) {
      if (sensor.length) {
        params.task__sensor__in = sensor.join(',');
      } else {
        params.task__sensor = sensor;
      }
    }

    if (reservation) {
      if (reservation.length) {
        params.task__reservation__in = reservation.join(',');
      } else {
        params.task__reservation = reservation;
      }
    }

    if (template) {
      if (template.length) {
        params.task__template__in = template.join(',');
      } else {
        params.task__template = template;
      }
    }

    if (pm_schedule) {
      if (pm_schedule.length) {
        params.task__pm_schedule__in = pm_schedule.join(',');
      } else {
        params.task__pm_schedule = pm_schedule;
      }
    }

    if (rfp) {
      if (rfp.length) {
        params.task__rfp__in = rfp.join(',');
      } else {
        params.task__rfp = rfp;
      }
    }

    if (task__archived !== null) {
      params.task__archived = task__archived;
    }

    if (task__overdue !== null) {
      params.task__overdue = task__overdue;
    }

    if (projectArchived !== null) {
      params.task__project__archived = projectArchived;
    }

    if (unassigned) {
      params.task__assignee_count = 0;
    }

    if (assigneeUserIds?.length) {
      params.assignees = Array.isArray(assigneeUserIds) ? assigneeUserIds.join(',') : assigneeUserIds;
    }

    if (category !== null) {
      if (category.length) {
        params.task__category__in = category.join(',');
      } else {
        params.task__category = category;
      }
    }

    if (priority !== null) {
      if (priority.length) {
        params.task__priority__in = priority.join(',');
      } else {
        params.task__priority = priority;
      }
    }

    if (status !== null) {
      if (status.length) {
        params.status__in = status.join(',');
      } else {
        params.status = status;
      }
    }

    if (completed_by) {
      params.completed_by = completed_by;
    }

    if (rfpMatchedUser) {
      if (Vue.prototype.$globals.selectedDashboard === 'sp') {
        params.rfp__company = rfpMatchedUser;
      } else {
        params.rfp__matched_service_provider = rfpMatchedUser;
      }
    }

    if (taskWorkTypeId) {
      params.task__work_type = taskWorkTypeId;
    }

    if (taskSource !== null) {
      params.task__work_order_request__isnull = taskSource;
    }

    if (taskSourceV2 === WorkType.Task) {
      params.task__work_order_request__isnull = true;
      params.task__pm_schedule__isnull = true;
    } else if (taskSourceV2 === WorkType.WorkOrder) {
      params.task__work_order_request__isnull = false;
    } else if (taskSourceV2 === WorkType.Schedule) {
      params.task__pm_schedule__isnull = false;
    }

    return this._get('company/tasks/instances/', params).then(m => new ListResponseModel(m, TaskInstanceModel));
  }

  getTaskInstances(task_id, limit = 50, offset = 0, ordering = null) {
    const params = {
      limit,
      offset,
      ordering,
    };

    return this._get(`company/tasks/${task_id}/instances/`, params).then(m => new ListResponseModel(m, TaskInstanceModel));
  }

  getTaskLogs({
    task_id,
    limit = 50,
    offset = 0,
    include_comments = null,
    ordering = 'created',
    field = null,
  }) {
    const params = {
      limit,
      offset,
      ordering,
    };

    if (include_comments !== null) {
      params.include_comments = include_comments;
    }

    if (field) {
      params.field = field;
    }

    return this._get(`company/tasks/${task_id}/logs/`, params).then(m => new ListResponseModel(m, TaskLogModel));
  }

  getTaskUri(id = null) {
    const uri = `${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin' : 'company'}/tasks/`;
    return id ? `${uri}${id}/` : uri;
  }

  createCompanyTask({
    name,
    due_date,
    completed,
    priority,

    // optional
    location,
    description,
    equipment, // EquipmentModel
    part, // PartModel
    sensor, // SensorDeviceModel
    reservation,
    rfp, // RFPModel
    project,
    category,
    work_type,

    attachments, // [FileModel,...]
    assignees, // [UserModel]

    repeat_type,
    recurring_end_date,
    recurring_every_x_days,
    recurring_every_x_weeks,
    recurring_days_of_week,
    recurring_every_x_months,
    recurring_days_of_month,
    notify_days_before_due_date,
  }) {
    if (due_date && due_date.toISOString) {
      due_date = due_date.toISOString();
    }

    const data = {
      name,
      due_date,
      completed,
      priority,
    };

    if (due_date) {
      data.due_date = due_date.split('T')[0];
    }

    if (location) {
      data.location = location;
    }

    if (description) {
      data.description = description;
    }

    if (equipment) {
      data.equipment = equipment;
    }

    if (part) {
      data.part = part;
    }

    if (sensor) {
      data.sensor = sensor;
    }

    if (reservation) {
      data.reservation = reservation;
    }

    if (rfp && rfp.id) {
      data.rfp = rfp.id;
    } else if (rfp) {
      data.rfp = parseInt(rfp);
    }

    if (project) {
      data.project = project;
    }

    if (category) {
      data.category = category;
    }

    if (work_type) {
      data.work_type = work_type;
    }

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    if (assignees) {
      data.assignees = assignees.map(a => a.id);
    }

    data.recurring_every_x_days = null;
    data.recurring_every_x_weeks = null;
    data.recurring_days_of_week = null;
    data.recurring_every_x_months = null;
    data.recurring_days_of_month = null;
    data.recurring_end_date = null;

    if (repeat_type) {
      data.recurring_end_date = recurring_end_date ? recurring_end_date.split('T')[0] : null;

      if (repeat_type === 'day') {
        data.recurring_every_x_days = recurring_every_x_days;
      } else if (repeat_type === 'week') {
        data.recurring_every_x_weeks = recurring_every_x_weeks;
        data.recurring_days_of_week = recurring_days_of_week;
      } else if (repeat_type === 'month') {
        data.recurring_every_x_months = recurring_every_x_months;
        data.recurring_days_of_month = recurring_days_of_month;
      }
    }

    if (notify_days_before_due_date) {
      data.notify_days_before_due_date = notify_days_before_due_date;
    }

    return this._post('company/tasks/', data).then(e => new TaskModel(e));
  }

  duplicateCompanyTask(id, params) {
    params.assignees = params.assignees.map(a => a.id);
    params.time_tracking = false;
    return this._post(`company/tasks/${id}/duplicate/`, params).then(t => t.id);
  }

  editCompanyTask(id, {
    name,
    due_date,
    completed,
    priority,

    // optional
    location,
    description,
    equipment, // EquipmentModel
    part, // PartModel
    sensor, // SensorDeviceModel
    rfp, // RFPModel
    project,
    category,
    work_type,

    attachments, // [FileModel,...]
    assignees, // [UserModel]

    repeat_type,
    recurring_end_date,
    recurring_every_x_days,
    recurring_every_x_weeks,
    recurring_days_of_week,
    recurring_every_x_months,
    recurring_days_of_month,
    notify_days_before_due_date,
  }) {
    if (due_date && due_date.toISOString) {
      due_date = due_date.toISOString();
    }

    const data = {
      name,
      due_date,
      completed,
      priority,
    };

    if (due_date) {
      data.due_date = due_date.split('T')[0];
    } else {
      data.due_date = null;
    }

    if (location) {
      data.location = location?.id || location;
    } else {
      data.location = null;
    }

    if (description) {
      data.description = description;
    }

    if (equipment) {
      data.equipment = equipment?.id || equipment;
    } else {
      data.equipment = null;
    }

    if (part) {
      data.part = part?.id || part;
    } else {
      data.part = null;
    }

    if (sensor) {
      data.sensor = sensor?.id || sensor;
    } else {
      data.sensor = null;
    }

    if (rfp && rfp.id) {
      data.rfp = rfp.id;
    } else if (rfp) {
      data.rfp = parseInt(rfp);
    } else {
      data.rfp = null;
    }

    data.project = project?.id || project || null;
    data.category = category?.id || category || null;
    data.work_type = work_type?.id || work_type || null;

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    if (assignees) {
      data.assignees = assignees.map(a => a.id);
    }

    data.recurring_every_x_days = null;
    data.recurring_every_x_weeks = null;
    data.recurring_days_of_week = null;
    data.recurring_every_x_months = null;
    data.recurring_days_of_month = null;
    data.recurring_end_date = null;

    if (repeat_type) {
      data.recurring_end_date = recurring_end_date ? recurring_end_date.split('T')[0] : null;

      if (repeat_type === 'day') {
        data.recurring_every_x_days = recurring_every_x_days;
      } else if (repeat_type === 'week') {
        data.recurring_every_x_weeks = recurring_every_x_weeks;
        data.recurring_days_of_week = recurring_days_of_week;
      } else if (repeat_type === 'month') {
        data.recurring_every_x_months = recurring_every_x_months;
        data.recurring_days_of_month = recurring_days_of_month;
      }
    }

    if (notify_days_before_due_date) {
      data.notify_days_before_due_date = notify_days_before_due_date;
    }

    return this._patch(this.getTaskUri(id), data).then(e => new TaskModel(e));
  }

  startTaskTimeTracking(instanceId) {
    return this._post(`company/tasks/instances/${instanceId}/time_tracking/start/`).then(t => new TaskInstanceModel(t));
  }

  stopTaskTimeTracking(instanceId) {
    return this._post(`company/tasks/instances/${instanceId}/time_tracking/stop/`).then(t => new TaskInstanceModel(t));
  }

  createTaskTimeTracking(instanceId, manualDate, manualElapsedSeconds) {
    const data = {
      manual_entry_date: moment(manualDate).format('YYYY-MM-DD'),
      time_in_seconds: Math.floor(Number(manualElapsedSeconds)),
    };
    return this._post(`company/tasks/instances/${instanceId}/time_tracking/`, data).then(t => new TaskInstanceModel(t));
  }

  updateTaskTimeTracking(taskTimeId, manualDate, manualElapsedSeconds) {
    const data = {
      manual_entry_date: moment(manualDate).format('YYYY-MM-DD'),
      time_in_seconds: Math.floor(Number(manualElapsedSeconds)),
    };
    return this._put(`company/tasks/instances/time_tracking/${taskTimeId}/`, data).then(t => new TaskInstanceModel(t));
  }

  deleteTaskTimeTracking(taskTimeId) {
    return this._post(`company/tasks/instances/time_tracking/${taskTimeId}/delete/`).then(t => new TaskInstanceModel(t));
  }

  getTaskTimeTracking(instanceId) {
    return this._get(`company/tasks/instances/${instanceId}/time_tracking/`).then(t => new TaskInstanceModel(t));
  }

  async getTaskChecklists(instanceId) {
    const results = await this._get(`company/tasks/instances/${instanceId}/checklists/`).then(l => l);
    return results.map(c => new TaskChecklistModel(c));
  }

  createTaskChecklist(instanceId, checklistName = 'Checklist', items = null) {
    const data = {
      name: checklistName,
      task_instance_id: instanceId,
    };

    // update assignee user objects to just pass the ids
    if (items?.length) {
      items.forEach(i => i.assignees = (i.assignees || []).map(a => a.id ?? a));
      data.items = items;
    }

    return this._post('company/tasks/instances/checklist/', data).then(l => new TaskChecklistModel(l));
  }

  updateTaskChecklist(checklistId, name) {
    const data = {
      name,
    };
    return this._patch(`company/tasks/instances/checklist/${checklistId}/`, data).then(l => new TaskChecklistModel(l));
  }

  updateTaskChecklistItemSort(checklistId, sortedItemIds) {
    return this._post(
      `company/tasks/instances/checklist/${checklistId}/sort/`,
      { checklist_item_ids: sortedItemIds },
    );
  }

  deleteTaskChecklist(checklistId) {
    return this._delete(`company/tasks/instances/checklist/${checklistId}/`);
  }

  createTaskChecklistItem(checklistId, itemName) {
    const data = {
      checklist_id: checklistId,
      name: itemName,
    };
    return this._post('company/tasks/instances/checklist/item/', data).then(l => new TaskChecklistModel(l));
  }

  updateTaskChecklistItem(itemId, { name, completed, assignees }) {
    const data = {
      completed,
    };

    if (name) data.name = name;
    if (assignees) data.assignees = assignees;

    return this._patch(`company/tasks/instances/checklist/item/${itemId}/`, data).then(l => new TaskChecklistModel(l));
  }

  deleteTaskChecklistItem(itemId) {
    return this._delete(`company/tasks/instances/checklist/item/${itemId}/`).then(l => new TaskChecklistModel(l));
  }

  updateTaskChecklistBatchCheck(checklistId) {
    return this._post(`company/tasks/instances/checklist/${checklistId}/batch/check/`).then(l => new TaskChecklistModel(l));
  }

  updateTaskChecklistBatchUnCheck(checklistId) {
    return this._post(`company/tasks/instances/checklist/${checklistId}/batch/uncheck/`).then(l => new TaskChecklistModel(l));
  }

  updateTaskChecklistBatchAssign(checklistId, assignee) {
    return this._post(`company/tasks/instances/checklist/${checklistId}/batch/assign/`, { assignee }).then(l => new TaskChecklistModel(l));
  }

  updateTaskChecklistBatchUnassign(checklistId) {
    return this._post(`company/tasks/instances/checklist/${checklistId}/batch/unassign/`).then(l => new TaskChecklistModel(l));
  }

  watchTask(taskId, userId) {
    return this._post(`company/tasks/${taskId}/watcher/${userId}/add/`);
  }

  unwatchTask(taskId, userId) {
    return this._post(`company/tasks/${taskId}/watcher/${userId}/delete/`);
  }

  setTaskCurrentInstanceCompleted(id) {
    const data = {
      completed: true,
    };

    return this._patch(`${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin' : 'company'}/tasks/${id}/`, data).then(e => new TaskModel(e));
  }

  completeCompanyTask(id, completed = true) {
    return this._patch(`${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin' : 'company'}/tasks/${id}/`, {
      completed,
    }).then(e => new TaskModel(e));
  }

  completeTaskInstance(id, completed_date, comment, completed_by_id) {
    return this._post(`company/tasks/instances/${id}/complete/`, {
      completed_date,
      comment,
      completed_by_id,
    }).then(e => new TaskModel(e));
  }

  editCompanyAssignees(id, assignees) {
    if (!id || !assignees) {
      return;
    }

    return this._patch(this.getTaskUri(id), { assignees: assignees.map(a => a.id) }).then(e => new TaskModel(e));
  }

  editCompanyTaskName(id, name) {
    if (!id || !name) {
      return;
    }
    return this._patch(this.getTaskUri(id), { name }).then(e => new TaskModel(e));
  }

  editCompanyTaskDescription(id, description) {
    return this._patch(this.getTaskUri(id), { description }).then(e => new TaskModel(e));
  }

  editCompanyTaskLocation(id, location) {
    return this._patch(this.getTaskUri(id), { location }).then(e => new TaskModel(e));
  }

  editCompanyTaskEquipment(id, equipment) {
    return this._patch(this.getTaskUri(id), { equipment }).then(e => new TaskModel(e));
  }

  editCompanyTaskPart(id, part) {
    return this._patch(this.getTaskUri(id), { part }).then(e => new TaskModel(e));
  }

  editCompanyTaskSensor(id, sensor) {
    return this._patch(this.getTaskUri(id), { sensor }).then(e => new TaskModel(e));
  }

  editCompanyTaskRfp(id, rfp) {
    return this._patch(this.getTaskUri(id), { rfp }).then(e => new TaskModel(e));
  }

  editCompanyTaskReservation(id, reservation) {
    return this._patch(this.getTaskUri(id), { reservation }).then(e => new TaskModel(e));
  }

  editCompanyTaskPriority(id, priority) {
    return this._patch(this.getTaskUri(id), { priority }).then(e => new TaskModel(e));
  }

  editCompanyTaskNotifyDays(id, notify_days_before_due_date) {
    return this._patch(this.getTaskUri(id), { notify_days_before_due_date }).then(e => new TaskModel(e));
  }

  editCompanyTaskAttachments(id, attachments) {
    return this._patch(this.getTaskUri(id), { attachments: attachments.map(f => f.id) }).then(e => new TaskModel(e));
  }

  editCompanyTaskInstanceStatus(instanceId, status) {
    const data = {
      status: status?.id || status || null,
    };
    return this._patch(`company/tasks/instances/${instanceId}/`, data).then(e => new TaskInstanceModel(e));
  }

  editCompanyTaskDueDate(id, due_date) {
    const data = {};

    data.recurring_every_x_days = null;
    data.recurring_every_x_weeks = null;
    data.recurring_days_of_week = null;
    data.recurring_every_x_months = null;
    data.recurring_days_of_month = null;
    data.recurring_end_date = null;

    data.due_date = due_date ? due_date.split('T')[0] : null;

    return this._patch(this.getTaskUri(id), data).then(e => new TaskModel(e));
  }

  editCompanyTaskProject(id, project) {
    return this._patch(`company/tasks/${id}/`, { project }).then(e => new TaskModel(e));
  }

  batchMoveTasks(task_ids, project) {
    const data = {
      task_ids,
      project,
    };
    return this._post('company/tasks/batch/move/', data);
  }

  batchUpdateTasksArchived(task_ids, archived = true) {
    const data = {
      task_ids,
      archived,
    };
    return this._post('company/tasks/batch/archived/', data);
  }

  batchEditTaskLocation(equipmentIds, locationId) {
    const data = {
      task_ids: equipmentIds,
      location: locationId,
    };

    return this._post('company/tasks/batch/location/', data);
  }

  batchDeleteTasks(task_ids) {
    const data = {
      task_ids,
    };
    return this._post('company/tasks/batch/delete/', data);
  }

  batchSetPriorityTasks(task_ids, priority) {
    const data = {
      task_ids,
      priority,
    };
    return this._post('company/tasks/batch/priority/', data);
  }

  batchAddTaskAssignee(task_ids, assignee) {
    const data = {
      task_ids,
      assignee,
    };
    return this._post('company/tasks/batch/assignee/add/', data);
  }

  batchRemoveTaskAssignee(task_ids, assignee) {
    const data = {
      task_ids,
      assignee,
    };
    return this._post('company/tasks/batch/assignee/remove/', data);
  }

  batchSetStatusTasks(task_ids, status) {
    const data = {
      task_ids,
      status,
    };
    return this._post('company/tasks/batch/status/', data);
  }

  batchCompleteTasks(task_ids, completed_date, comment, completed_by_id) {
    const data = {
      task_ids,
      completed_date,
      comment,
      completed_by_id,
    };
    return this._post('company/tasks/batch/complete/', data);
  }

  batchScheduleTasks(task_ids, {
    due_date,
    repeat_type,
    recurring_end_date,
    recurring_every_x_days,
    recurring_every_x_weeks,
    recurring_days_of_week,
    recurring_every_x_months,
    recurring_days_of_month,
  }) {
    if (due_date && due_date.toISOString) {
      due_date = due_date.toISOString();
    }

    const data = {
      task_ids,
      due_date,
    };

    if (due_date) {
      data.due_date = due_date.split('T')[0];
    }

    data.recurring_every_x_days = null;
    data.recurring_every_x_weeks = null;
    data.recurring_days_of_week = null;
    data.recurring_every_x_months = null;
    data.recurring_days_of_month = null;
    data.recurring_end_date = null;

    if (repeat_type) {
      data.recurring_end_date = recurring_end_date ? recurring_end_date.split('T')[0] : null;

      if (repeat_type === 'day') {
        data.recurring_every_x_days = recurring_every_x_days;
      } else if (repeat_type === 'week') {
        data.recurring_every_x_weeks = recurring_every_x_weeks;
        data.recurring_days_of_week = recurring_days_of_week;
      } else if (repeat_type === 'month') {
        data.recurring_every_x_months = recurring_every_x_months;
        data.recurring_days_of_month = recurring_days_of_month;
      }
    }
    return this._post('company/tasks/batch/schedule/', data);
  }

  exportTask(id) {
    return this._post(`${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin' : 'company'}/tasks/${id}/email_ics/`);
  }

  // ///// Task Templates

  getTaskTemplates({
    search,
    ordering,
    limit,
    offset,
    categories = [],
    archived = false,
    forSelectUi = false,
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      archived,
    };

    if (categories?.length) {
      params.category__in = categories;
    }

    let uri = 'company/tasks/templates/';

    if (forSelectUi) {
      uri += 'select_ui/';
    }

    return this._get(uri, params).then(t => new ListResponseModel(t, TaskTemplateModel));
  }

  getTaskTemplate(id) {
    return this._get(`company/tasks/templates/${id}/`).then(t => new TaskTemplateModel(t));
  }

  createTaskTemplate({
    name,
    description,
    category,
    checklists,
    attachments,
  }) {
    const data = {
      name,
      description,
      category,
    };

    if (checklists?.length) {
      data.checklists = checklists.map(c => ({
        name: c.name,
        items: c.items.map(i => ({ name: i.name })),
      }));
    }

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    return this._post('company/tasks/templates/', data).then(t => new TaskTemplateModel(t));
  }

  editTaskTemplate(id, {
    name,
    description,
    category,
    checklists,
    attachments,
    sync_related_tasks = false,
  }) {
    const data = {
      name,
      description,
      category,
      sync_related_tasks,
    };

    if (checklists?.length) {
      data.checklists = checklists.map(c => ({
        id: c.id || null,
        name: c.name,
        items: c.items.map(i => ({ id: i.id || null, name: i.name })),
      }));
    } else {
      data.checklists = [];
    }

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    } else {
      data.attachments = [];
    }

    return this._patch(`company/tasks/templates/${id}/`, data).then(t => new TaskTemplateModel(t));
  }

  archiveTaskTemplate(id, updateActiveTasks = false, updateCompleteTasks = false) {
    return this
      ._post(
        `company/tasks/templates/${id}/archive/`,
        {
          archive_related_non_completed_tasks: updateActiveTasks,
          archive_related_completed_tasks: updateCompleteTasks,
        },
      )
      .then(t => new TaskTemplateModel(t));
  }

  restoreTaskTemplate(id) {
    return this
      ._patch(
        `company/tasks/templates/${id}/`,
        { archived: false },
      )
      .then(t => new TaskTemplateModel(t));
  }

  deleteTaskTemplate(id, updateActiveTasks = false, updateCompleteTasks = false) {
    return this
      ._post(
        `company/tasks/templates/${id}/delete/`,
        {
          archive_related_non_completed_tasks: updateActiveTasks,
          archive_related_completed_tasks: updateCompleteTasks,
        },
      )
      .then(t => new TaskTemplateModel(t));
  }

  async getTaskTemplateWorkItemCounts(id) {
    if (!id) return {};

    const nonCompleteTasks = await this.getProjectCompanyTasks(null, { template: id, completed: false, limit: 0 });
    const completeTasks = await this.getCompanyTaskInstances({ template: id, completed: true, limit: 0 });

    return {
      nonComplete: nonCompleteTasks.count,
      complete: completeTasks.count,
    };
  }

  createTaskFromTemplate(id, {
    equipment,
    location,
    part,
    sensor,
    project,
    reservation,
  }) {
    const data = {
      equipment,
      location,
      part,
      sensor,
      project,
      reservation,
    };
    return this._post(`company/tasks/templates/${id}/task/`, data).then(t => new TaskModel(t));
  }

  getTaskTemplateUiSettings() {
    return this._get('company/tasks/templates/ui_settings/').then(settings => new TaskTemplateUiSettingsModel(settings));
  }

  updateTaskTemplateUiSettings({
    search = null,
    sort = null,
    task_categories = null,
  }) {
    const data = {
      search,
      sort,
    };

    if (!task_categories?.length) {
      data.task_categories = [];
    } else {
      data.task_categories = task_categories.map(c => c.id);
    }

    return this._patch('company/tasks/templates/ui_settings/', data).then(settings => new TaskTemplateUiSettingsModel(settings));
  }

  cancelRfp(id, reason, reason_details = '') {
    const params = {
      reason,
    };

    if (reason_details) {
      params.reason_details = reason_details;
    }
    return this._post(`${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin' : 'company'}/rfps/${id}/cancel/`, params);
  }

  removeProvider(id, company, reason, reason_details = '') {
    const params = {
      provider_ids: [company],
      reason,
    };
    if (reason_details) {
      params.reason_details = reason_details;
    }
    return this._post(`company/rfps/${id}/unmatch/`, params);
  }

  addProviderToRfp(id, companyId) {
    const params = {
      provider_ids: [companyId],
      raise_exception_already_matched: true,
    };
    return this._post(`company/rfps/${id}/match/`, params);
  }

  declineServiceAsSP(id, reason, reason_details = '') {
    const params = { reason };
    if (reason_details) {
      params.reason_details = reason_details;
    }
    return this._post(`provider/rfps/${id}/decline/`, params);
  }

  submitQuote(id, {
    proposal_number,
    service_description,
    subtotal_price,
    shipping_price,
    shipping_cost_unknown,
    tax_price,
    tax_cost_unknown,
    lead_time,
    quote_valid_until,
    terms,
    terms_other,
    attachments, // [FileModel,...]
  }) {
    const data = {
      proposal_number,
      service_description,
      subtotal_price,
    };

    if (shipping_cost_unknown) {
      data.shipping_cost_unknown = true;
      data.shipping_price = null;
    } else if (shipping_price) {
      data.shipping_price = shipping_price;
    }

    if (tax_cost_unknown) {
      data.tax_cost_unknown = tax_cost_unknown;
      data.tax_price = null;
    } else if (tax_price) {
      data.tax_price = tax_price;
    }

    if (lead_time) {
      data.lead_time = lead_time;
    }

    if (terms_other) {
      data.terms_other = terms_other;
    } else if (terms) {
      data.terms = terms;
    }

    if (quote_valid_until) {
      data.quote_valid_until = quote_valid_until;
    }

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    return this._post(`${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin' : 'company'}/rfps/${id}/quotes/`, data).then(e => new QuoteModel(e));
  }

  respondToQuote(id, quote, action, reason = '', reason_details = '') {
    return this._post(`${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin' : 'company'}/rfps/${id}/respond_to_quote/`, {
      action, // accept|reject
      quote,
      reason,
      reason_details,
    });
  }

  cancelQuote(id) {
    return this._post(`provider/quote/${id}/withdraw/`);
  }

  submitInvoice(id, {
    invoice_number,
    service_description,
    subtotal_price,
    shipping_price,
    tax_price,
    terms,
    terms_other,
    is_final_invoice,
    direct_payment_link,
    due_date,
    attachments, // [FileModel,...]
    notes,
  }) {
    const data = {
      invoice_number,
      service_description,
      subtotal_price,
      is_final_invoice,
    };

    if (notes) {
      data.notes = notes;
    }

    if (shipping_price) {
      data.shipping_price = shipping_price;
    }

    if (tax_price) {
      data.tax_price = tax_price;
    }

    if (terms_other) {
      data.terms_other = terms_other;
    } else if (terms) {
      data.terms = terms;
    }

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    if (direct_payment_link) {
      data.direct_payment_link = direct_payment_link;
    }

    if (due_date) {
      data.due_date = due_date;
    }

    return this._post(`company/rfps/${id}/invoices/`, data).then(e => new InvoiceModel(e));
  }

  cancelInvoice(rfp_id, invoice_id) {
    return this._post(`company/rfps/${rfp_id}/invoices/${invoice_id}/cancel/`);
  }

  resendInvoice(rfp_id, invoice_id) {
    return this._post(`company/rfps/${rfp_id}/invoices/${invoice_id}/resend/`);
  }

  editInvoice(rfpId, invoiceId, {
    invoice_number,
    service_description,
    subtotal_price,
    shipping_price,
    tax_price,
    terms,
    terms_other,
    is_final_invoice,
    direct_payment_link,
    due_date,
    attachments, // [FileModel,...]
    notes,
  }) {
    const data = {
      invoice_number,
      service_description,
      subtotal_price,
      is_final_invoice,
      direct_payment_link: direct_payment_link || '',
      notes: notes || null,
    };

    if (shipping_price) {
      data.shipping_price = shipping_price;
    } else {
      data.shipping_price = null;
    }

    if (tax_price) {
      data.tax_price = tax_price;
    } else {
      data.tax_price = null;
    }

    if (terms_other) {
      data.terms_other = terms_other;
    } else if (terms) {
      data.terms = terms;
    }

    if (due_date) {
      data.due_date = due_date;
    }

    if (attachments) {
      data.attachments = attachments.map(f => f.id);
    }

    return this._patch(`company/rfps/${rfpId}/invoices/${invoiceId}/`, data).then(e => new InvoiceModel(e));
  }

  editRFPService(rfpId, id, {
    type,

    short_description,
    service_date,
    estimated_ship_date,
    estimated_delivery_date,
    tracking_number,
    other_details,
  }) {
    const data = {
      type,
      short_description,
      other_details,
      tracking_number: '',
      service_date: null,
      estimated_ship_date: null,
      estimated_delivery_date: null,
    };

    if (type === 'on_site_service') {
      data.service_date = service_date || null;
    } else if (type === 'delivery') {
      data.estimated_ship_date = estimated_ship_date || null;
      data.estimated_delivery_date = estimated_delivery_date || null;
      data.tracking_number = tracking_number;
    }

    return this._patch(`company/rfps/${rfpId}/scheduled_services/${id}/`, data).then(r => new ServiceModel(r));
  }

  removeRFPService(rfpId, id) {
    return this._delete(`company/rfps/${rfpId}/scheduled_services/${id}/`);
  }

  scheduleRFPService(id, {
    type,

    short_description,
    service_date,
    estimated_ship_date,
    estimated_delivery_date,
    tracking_number,
    other_details,
  }) {
    const data = {
      type,
    };

    if (short_description) {
      data.short_description = short_description;
    }

    if (type === 'on_site_service') {
      if (service_date) {
        data.service_date = service_date;
      }
    } else if (type === 'delivery') {
      if (estimated_ship_date) {
        data.estimated_ship_date = estimated_ship_date;
      }
      if (estimated_delivery_date) {
        data.estimated_delivery_date = estimated_delivery_date;
      }
      if (tracking_number) {
        data.tracking_number = tracking_number;
      }
    }

    if (other_details) {
      data.other_details = other_details;
    }

    return this._post(`company/rfps/${id}/scheduled_services/`, data).then(r => new ServiceModel(r));
  }

  getStates() {
    return this._get('locations/states/');
  }

  getTerritories(search = '', state = '', limit = 10) {
    return this._get('locations/counties/', {
      search,
      state,
      limit,
    }).then(m => new ListResponseModel(m, TerritoryModel));
  }

  getAllTerritories() {
    return this._get('locations/counties/all/').then(m => new ListResponseModel(m, TerritoryModel));
  }

  archiveTask(id) {
    return this._patch(`company/tasks/${id}/`, {
      archived: true,
    });
  }

  archivePart(id) {
    return this._patch(`company/parts/${id}/`, {
      archived: true,
    });
  }

  restoreEquipment(id, locationId = null) {
    const data = { archived: false };

    if (locationId !== null) {
      data.location = locationId;
    }

    return this._patch(`company/equipments/${id}/`, data);
  }

  restoreTask(id) {
    return this._patch(`company/tasks/${id}/`, {
      archived: false,
      completed: false,
    });
  }

  restorePart(id) {
    return this._patch(`company/parts/${id}/`, {
      archived: false,
    });
  }

  restoreLocation(id) {
    return this._patch(`company/locations/${id}/`, {
      archived: false,
    });
  }

  deleteTask(id) {
    return this._delete(`company/tasks/${id}/`);
  }

  // Task Instance
  editCompanyTaskInstance(id, data) {
    return this._patch(`company/tasks/instances/${id}/`, data).then(e => new TaskInstanceModel(e));
  }

  exportTaskInstance(id) {
    return this._post(`company/tasks/instances/${id}/email_ics/`);
  }

  archiveTaskInstance(id) {
    return this._patch(`company/tasks/instances/${id}/`, {
      archived: true,
    });
  }

  restoreTaskInstance(id) {
    return this._patch(`company/tasks/instances/${id}/`, {
      archived: false,
      completed: false,
    });
  }

  useCustomQRCode(code) {
    return this._get(`qr_code/${code}/`);
  }

  getTags(search = '', limit = 5000, offset = 0) {
    const params = {
      search,
      limit,
      ordering: 'name',
      offset,
    };

    return this._get('company/tags/', params).then(m => new ListResponseModel(m, TagModel));
  }

  getCustomFields({ search = '', limit = 5000, offset = 0, custom_field_type = 'equipment', active = true }, forAdmin = false) {
    const params = {
      search,
      limit,
      ordering: 'name',
      offset,
      active,
    };

    if (custom_field_type) {
      params.custom_field_type = custom_field_type;
    }

    if (forAdmin) {
      delete params.active;
    }

    return this._get('company/custom_fields/', params).then(m => new ListResponseModel(m, CustomFieldModel));
  }

  getTagsForAdmin({ search = '', limit = 5000, offset = 0, active = null }) {
    const params = {
      search,
      limit,
      offset,
      ordering: 'name',
    };

    if (active !== null) {
      params.active = active;
    }

    return this._get('company/tags/stats/', params).then(m => new ListResponseModel(m, TagModel));
  }

  getTag(id) {
    return this._get(`company/tags/${id}/`).then(e => new TagModel(e));
  }

  editTag(id, name, active) {
    return this._patch(`company/tags/${id}/`, {
      name,
      active,
    }).then(e => new TagModel(e));
  }

  addTag(name, active = true) {
    return this._post('company/tags/', {
      name,
      active,
    }).then(e => new TagModel(e));
  }

  addCustomField(name, custom_field_type = 'equipment') {
    return this._post('company/custom_fields/', {
      name,
      custom_field_type,
    }).then(e => new CustomFieldModel(e));
  }

  editCustomField(id, name, active) {
    return this._patch(`company/custom_field/${id}/`, {
      name,
      active,
    }).then(e => new CustomFieldModel(e));
  }

  getEquipmentFilters(search, location = 0, status = '', rfp = 0, archived = false, equipmentTypeId = '', tags = []) {
    const params = {
      search,
      archived,
    };

    if (tags && tags.length) {
      params.tags__or = tags.map(t => t.id).join(',');
    }

    if (location) {
      params.location = location;
    }

    if (rfp) {
      params.rfp__id = rfp;
    }

    if (status) {
      params.status = status;
    }

    if (equipmentTypeId) {
      params.equipment_type__in = equipmentTypeId;
    }

    return params;
  }

  getLocationEquipmentMetrics() {
    return this._get('metrics/eo/equipment/count_by_root_location/').then(m => new ListResponseModel(m, LocationEquipmentModel));
  }

  getEquipmentAgeMetrics(location_id) {
    const params = {};
    if (location_id) {
      params.location__root_id = location_id;
    }
    return this._get('metrics/eo/equipment/manufacture_year_by_type_chart/', params).then(m => new ListResponseModel(m, EquipmentAgeModel));
  }

  getEquipmentStatusMetrics({ search = '', location = 0, status = '', rfp = 0, archived = false, equipmentTypeId = '', tags = [] }) {
    const params = this.getEquipmentFilters(search, location, status, rfp, archived, tags);
    applyEquipmentTypeFilterToApiParams(params, equipmentTypeId);
    return this._get('metrics/eo/equipment/count_by_status/', params).then(m => new ListResponseModel(m, EquipmentStatusModel));
  }

  getEquipmentAverageAgeMetric({ search, location = 0, status = '', rfp = 0, archived = false, equipmentTypeId = '', tags = [] }) {
    const params = this.getEquipmentFilters(search, location, status, rfp, archived, tags);
    applyEquipmentTypeFilterToApiParams(params, equipmentTypeId);
    return this._get('metrics/eo/equipment/average_age/', params).then(m => (m));
  }

  getEquipmentTypeMetrics({ search, location = 0, status = '', rfp = 0, archived = false, equipmentTypeId = '', tags = [] }) {
    const params = this.getEquipmentFilters(search, location, status, rfp, archived, tags);
    applyEquipmentTypeFilterToApiParams(params, equipmentTypeId);
    return this._get('metrics/eo/equipment/count_by_type/', params).then(m => new ListResponseModel(m, EquipmentTypeModel));
  }

  getSPSummaryMetrics() {
    return this._get('metrics/sp/dashboard/').then(m => new SPDashboardCountsModel(m));
  }

  getSPPerformanceMetrics(period = 365) {
    const params = {
      date_gte: moment().subtract(period, 'days').format('YYYY-MM-DD'),
    };
    return this._get('metrics/sp/dashboard/performance/', params).then(m => new SPDasboardPerformanceMetricsModel(m));
  }

  getSPCustomerMetrics(period = 365) {
    const startDate = moment().subtract(period, 'days').format('YYYY-MM-DD');
    const params = {
      date_gte: startDate,
    };
    return this._get('metrics/sp/client/overview/', params).then(m => (m));
  }

  getSPAcceptedRFPTypeMetrics(period = 365) {
    const startDate = moment().subtract(period, 'days').format('YYYY-MM-DD');
    const params = {
      date_gte: startDate,
    };
    return this._get('metrics/sp/rfp/accepted/count_by_type/', params).then(m => new ListResponseModel(m, RFPTypeCountModel));
  }

  getSPMarketplaceDashboardMetrics(period = 365) {
    const startDate = moment().subtract(period, 'days').format('YYYY-MM-DD');
    const params = {
      date_gte: startDate,
    };
    return this._get('marketplace/services/sp_dashboard_metrics/', params).then(m => (m));
  }

  getTaskComments(id, limit = 50, offset = 0) {
    const params = {
      limit,
      offset,
      active: true,
    };
    return this._get(`company/tasks/${id}/comments/`, params).then(m => new ListResponseModel(m, CommentModel));
  }

  addTaskComment(id, message) {
    return this._post(`company/tasks/${id}/comment/`, {
      message,
    }).then(res => new CommentModel(res));
  }

  editComment(id, message, active) {
    return this._patch(`comments/${id}/`, {
      message,
      active,
    }).then(res => new CommentModel(res));
  }

  editFile(id, data) {
    return this._patch(`file_uploads/uploads/${id}/`, data);
  }

  // ///// Part Containers

  getPartContainers({
    search = '',
    ordering = 'name',
    limit = 50,
    offset = 0,
    location__ancestor_hierarchy__ancestor_id = 0,
    equipment = '',
    archived = false,
    tags = null,
    location = 0,
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      archived,
    };

    if (tags && tags.length) {
      params.tags__or = tags.map(t => t.id).join(',');
    }

    if (location__ancestor_hierarchy__ancestor_id) {
      params.location__ancestor_hierarchy__ancestor_id = location__ancestor_hierarchy__ancestor_id;
    }

    if (equipment) {
      params.equipment = equipment;
    }

    if (location) {
      params.location = location;
    }

    return this._get('company/parts/containers/', params).then(b => new ListResponseModel(b, PartContainerModel));
  }

  createPartContainer({
    name,
    description,
    location,
    image,
    images,
    tags,
    qr_code,
  }) {
    const data = {
      name,
      description,
      tags: tags || [],
      qr_code,
    };

    if (location) {
      data.location = location;
    }

    images = images || [];

    // set the cover to the first image if none was selected
    if (!image && images.length > 0) {
      image = images[0];
    }

    data.image = image ? image.id : null;
    if (images) {
      data.images = images.map(f => f.id);
    }

    return this._post('company/parts/containers/', data).then(b => new PartContainerModel(b));
  }

  updatePartContainer(containerId, {
    name,
    description,
    location,
    tags,
    qr_code,
    image,
    images,
  }) {
    const data = {
      name,
      description,
      tags: tags || [],
      qr_code,
    };

    if (location) {
      data.location = location;
    } else {
      data.location = null;
    }

    images = images || [];

    // set the cover to the first image if none was selected
    if (!image && images.length > 0) {
      image = images[0];
    }

    data.image = image ? image.id : null;
    if (images) {
      data.images = images.map(f => f.id);
    }

    return this._patch(`company/parts/containers/${containerId}/`, data).then(b => new PartContainerModel(b));
  }

  editPartContainerLocation(containerId, location) {
    return this._patch(`company/parts/containers/${containerId}/`, { location }).then(b => new PartContainerModel(b));
  }

  archivePartContainer(containerId, transferBinId = null) {
    const data = {};

    if (transferBinId) {
      data.target_container = transferBinId;
    }

    return this._post(`company/parts/containers/${containerId}/archive/`, data);
  }

  restorePartContainer(containerId) {
    return this._post(`company/parts/containers/${containerId}/restore/`);
  }

  batchEditPartContainerLocation(containerIds, locationId) {
    const data = {
      container_ids: containerIds,
      location: locationId,
    };

    return this._post('company/parts/containers/batch/location/', data);
  }

  batchArchivePartContainers(containerIds) {
    const data = {
      container_ids: containerIds,
      archived: true,
    };
    return this._post('company/parts/containers/batch/archived/', data);
  }

  getPartContainer(containerId) {
    return this._get(`company/parts/containers/${containerId}/`).then(b => new PartContainerModel(b));
  }

  getPartContainerInventories({
    part = 0,
    container = 0,
    location = 0,
    search,
    ordering = 'name',
    limit = 50,
    offset = 0,
    search_context = '',
    format = '',
  }) {
    const data = {
      search,
      ordering,
      limit,
      offset,
      format,
    };

    if (container) {
      data.container = container;
    }

    if (part) {
      data.part = part;
    }

    if (location) {
      data.container__location__ancestor_hierarchy__ancestor_id = location;
    }

    if (search_context) {
      data.search_context = search_context;
    }

    return this._get('company/parts/containers/inventory/', data).then(m => new ListResponseModel(m, PartContainerInventoryModel));
  }

  createPartContainerInventory({
    container,
    part,
    quantity_on_hand,
    target_quantity,
  }) {
    return this._post(
      'company/parts/containers/inventory/',
      {
        container,
        part,
        quantity_on_hand,
        target_quantity,
      },
    ).then(c => new PartContainerInventoryModel(c));
  }

  updatePartContainerInventory(inventoryId, {
    container,
    part,
    quantity_on_hand,
    target_quantity,
  }) {
    const data = {
      container,
      part,
      quantity_on_hand,
      target_quantity,
    };
    return this._patch(`company/parts/containers/inventory/${inventoryId}/`, data).then(c => new PartContainerInventoryModel(c));
  }

  transferPartContainerInventory(sourceInventoryId, { destinationContainer, quantity, transferAll = false }) {
    const data = {
      target_container: destinationContainer,
      quantity,
    };

    if (transferAll) {
      data.transfer_all = true;
    }

    return this._post(`company/parts/containers/inventory/${sourceInventoryId}/transfer/`, data);
  }

  archivePartContainerInventory(inventoryId) {
    const data = {
      archived: true,
    };
    return this._patch(`company/parts/containers/inventory/${inventoryId}/`, data).then(c => new PartContainerInventoryModel(c));
  }

  getPartContainerInventoryHistory(inventoryId, limit = 20, offset = 0) {
    const data = {
      limit,
      offset,
    };
    return this._get(`company/parts/containers/inventory/${inventoryId}/logs/`, data).then(h => new ListResponseModel(h, PartContainerInventoryLogModel));
  }

  // ///// Projects

  getProjects({
    search = '',
    ordering = 'name',
    limit = 10,
    offset = 0,
    archived = false,
    ids = null,
    excludeIds = null,
  }) {
    const params = {
      search,
      ordering,
      limit,
      offset,
      archived,
    };

    if (ids) {
      params.id__in = ids.join(',');
    }

    if (excludeIds) {
      params.id__not = excludeIds.join(',');
    }

    return this._get('company/projects/', params).then(b => new ListResponseModel(b, ProjectModel));
  }

  getProjectsForProjectsUi({
    search = '',
    ordering = 'name',
    limit = 5,
    offset = 0,
    archived = false,
    ids = null,
    excludeIds = null,
    taskArchived = null,
    taskOverdue = null,
    taskAssignees = null,
    taskAssigneeIgnoreChecklists = null,
    taskCategoryIds = null,
    taskStatusIds = null,
    taskPriorityIds = null,
    taskEquipmentId = null,
    taskPartId = null,
    taskSensorId = null,
    taskReservationId = null,
    taskRfpId = null,
    taskWorkTypeId = null,
    taskLocationId = null,
    taskDueDateAfter = null,
    taskDueDateBefore = null,
    taskCompletedDateAfter = null,
    taskCompletedDateBefore = null,
    taskAssigneeCount = null,
    taskSource = null,
    taskSourceV2 = null,
    returnAll = false,
  }) {
    const params = {
      search,
      ordering,
      archived,
    };

    if (returnAll) {
      params.return_all = true;
    } else {
      params.limit = limit;
      params.offset = offset;
    }

    if (ids?.length) {
      params.id__in = ids.join(',');
    }

    if (excludeIds?.length) {
      params.id__not = excludeIds.join(',');
    }

    if (taskArchived !== null) {
      params.task__archived = taskArchived;
    }

    if (taskOverdue !== null) {
      params.task__overdue = taskOverdue;
    }

    if (taskAssignees?.length) {
      params.task__assignees = taskAssignees.join(',');
    }

    if (taskAssigneeIgnoreChecklists !== null) {
      params.task__assignee_ignore_checklists = taskAssigneeIgnoreChecklists;
    }

    if (taskCategoryIds?.length) {
      params.task__category = taskCategoryIds.join(',');
    }

    if (taskStatusIds?.length) {
      params.task__status = taskStatusIds.join(',');
    }

    if (taskPriorityIds?.length) {
      params.task__priority = taskPriorityIds.join(',');
    }

    if (taskEquipmentId) {
      params.task__equipment = taskEquipmentId;
    }

    if (taskPartId) {
      params.task__part = taskPartId;
    }

    if (taskSensorId) {
      params.task__sensor = taskSensorId;
    }

    if (taskReservationId) {
      params.task__reservation = taskReservationId;
    }

    if (taskRfpId) {
      params.task__rfp = taskRfpId;
    }

    if (taskWorkTypeId) {
      params.task__work_type = taskWorkTypeId;
    }

    if (taskLocationId) {
      params.task__location = taskLocationId;
    }

    if (taskCompletedDateBefore) {
      params.task__completed_date_before = taskCompletedDateBefore;
    }

    if (taskCompletedDateAfter) {
      params.task__completed_date_after = taskCompletedDateAfter;
    }

    if (taskDueDateBefore) {
      params.task__due_date_before = taskDueDateBefore;
    }

    if (taskDueDateAfter) {
      params.task__due_date_after = taskDueDateAfter;
    }

    if (taskSource !== null) {
      params.task__work_order_request__isnull = taskSource;
    }

    if (taskSourceV2 === WorkType.Task) {
      params.task__work_order_request__isnull = true;
      params.task__pm_schedule__isnull = true;
    } else if (taskSourceV2 === WorkType.WorkOrder) {
      params.task__work_order_request__isnull = false;
    } else if (taskSourceV2 === WorkType.Schedule) {
      params.task__pm_schedule__isnull = false;
    }

    if (taskAssigneeCount !== null) {
      params.task__assignee_count = taskAssigneeCount;
    }

    return this._get('company/projects/projects_ui/', params).then(b => new ListResponseModel(b, ProjectModel));
  }

  createProject({
    name,
    description,
    color,
  }) {
    const data = {
      name,
      description,
      color,
    };

    return this._post('company/projects/', data).then(b => new ProjectModel(b));
  }

  updateProject(projectId, {
    name,
    description,
    color,
  }) {
    const data = {
      name,
      description,
      color,
    };

    return this._patch(`company/projects/${projectId}/`, data).then(b => new ProjectModel(b));
  }

  getProject(projectId) {
    return this._get(`company/projects/${projectId}/`).then(b => new ProjectModel(b));
  }

  archiveProject(projectId) {
    return this._patch(`company/projects/${projectId}/`, { archived: true }).then(b => new ProjectModel(b));
  }

  restoreProject(projectId) {
    return this._patch(`company/projects/${projectId}/`, { archived: false }).then(b => new ProjectModel(b));
  }

  deleteProject(projectId) {
    return this._delete(`company/projects/${projectId}/`);
  }

  getProjectFilters() {
    return this
      ._get('company/projects/filters/')
      .then(f => new ProjectsFilterModel(f));
  }

  updateProjectFilters({
    search = null,
    archived = null,
    projectIds = null,
    categoryIds = null,
    priorityIds = null,
    statusIds = null,
    equipmentIds = null,
    partIds = null,
    locationIds = null,
    rfpIds = null,
    workTypeId = null,
    unassigned = null,
    assigneeIgnoreChecklists = null,
    assignedUsers = null,
    dueDateAfter = null,
    dueDateBefore = null,
    completedDateAfter = null,
    completedDateBefore = null,
    taskSource = null,
    taskSourceV2 = null,
    uiFoldedProjectIds = null,
    sensorIds = null,
    reservationIds = null,
  }) {
    const data = {
      search,
      task_archived_filter: false,
      unassigned_filter: false,
      due_date_after: null,
      due_date_before: null,
      completed_date_after: null,
      completed_date_before: null,
      project_filters: [],
      category_filters: '',
      priority_filter: '',
      status_filters: [],
      equipment_filters: [],
      part_filters: [],
      location_filters: [],
      rfp_filters: [],
      work_type_filter: null,
      assignee_filter_users: [],
      sensor_filters: [],
      reservation_filters: [],
      uiFoldedProjectIds: [],
      work_order_request_source_filter: taskSource,
      task_source_filter: taskSourceV2,
    };

    if (archived !== null) {
      data.task_archived_filter = archived;
    }

    if (unassigned !== null) {
      data.unassigned_filter = unassigned;
    }

    if (assigneeIgnoreChecklists !== null) {
      data.assignee_ignore_checklists = assigneeIgnoreChecklists;
    }

    if (dueDateAfter) {
      data.due_date_after = dueDateAfter;
    }

    if (dueDateBefore) {
      data.due_date_before = dueDateBefore;
    }

    if (completedDateAfter) {
      data.completed_date_after = completedDateAfter;
    }

    if (completedDateBefore) {
      data.completed_date_before = completedDateBefore;
    }

    if (projectIds?.length) {
      data.project_filters = projectIds;
    }

    if (categoryIds?.length) {
      data.category_filters = categoryIds;
    }

    if (priorityIds?.length) {
      data.priority_filter = priorityIds.join(',');
    }

    if (statusIds?.length) {
      data.status_filters = statusIds;
    }

    if (equipmentIds?.length) {
      data.equipment_filters = equipmentIds;
    }

    if (partIds?.length) {
      data.part_filters = partIds;
    }

    if (locationIds?.length) {
      data.location_filters = locationIds;
    }

    if (rfpIds?.length) {
      data.rfp_filters = rfpIds;
    }

    if (workTypeId) {
      data.work_type_filter = workTypeId;
    }

    if (sensorIds?.length) {
      data.sensor_filters = sensorIds;
    }

    if (reservationIds?.length) {
      data.reservation_filters = reservationIds;
    }

    if (assignedUsers?.length) {
      data.assignee_filter_users = assignedUsers;
    }

    if (uiFoldedProjectIds !== null) {
      data.ui_folded_project_ids = uiFoldedProjectIds;
    }

    return this._patch('company/projects/filters/', data).then(f => new ProjectsFilterModel(f));
  }

  sortProjects({
    projectIds = [],
  }) {
    const data = {
      project_ids: projectIds,
    };

    return this._post('company/projects/sort/', data);
  }

  getIncentiveReports({ ordering = 'submission_date', offset, limit, status = 'active' }) {
    const params = {
      ordering,
      offset,
      limit,
    };

    if (status === 'active') {
      params.status__not = 'draft';
    } else {
      params.status = status;
    }

    return this._get(`${Vue.prototype.$globals.selectedDashboard === 'owner' ? 'helix_admin/' : ''}incentives/reports/`, params).then(r => new ListResponseModel(r, IncentiveReportModel));
  }

  getIncentiveReportConstants() {
    return this._get('incentives/reports/constants/').then(response => ({
      utilities: (response.utilities || []),
      asset_types: Object.keys((response.asset_types || {})).map(key => ({
        id: response.asset_types[key],
        display_label: key,
      })),
    }));
  }

  createIncentiveReport() {
    return this._post('incentives/reports/').then(r => new IncentiveReportModel(r));
  }

  getIncentiveReport(id) {
    return this._get(`incentives/reports/${id}/`).then(r => new IncentiveReportModel(r));
  }

  submitIncentiveReport(id, payment_method) {
    const data = {};
    if (payment_method) {
      data.payment_method = payment_method;
    }

    return this._post(`incentives/reports/${id}/submit/`, data);
  }

  applyIncentiveReportPromoCode(id, code) {
    return this._post(`incentives/reports/${id}/promo_code/`, { code }).then(r => new IncentiveReportPromoCodeModel(r));
  }

  addIncentiveReportProperty(id, {
    name,

    street_address,
    street_address_line_2,
    city,
    state,
    zip,
    phone,
    phone_ext,

    square_footage,
    property_type,
    construction_type,
    asset_type,
    unit_count,
    individually_metered_units,
    electric_utility,
    gas_utility,
    water_utility,
  }) {
    return this._post(`incentives/reports/${id}/property/`, {
      name,

      street_address,
      street_address_line_2,
      city,
      state,
      zip,
      phone,
      phone_ext,

      square_footage,
      property_type,
      construction_type,
      asset_type,

      unit_count,
      individually_metered_units,
      electric_utility,
      gas_utility,
      water_utility,
    });
  }

  editIncentiveReportProperty(id, {
    name,

    street_address,
    street_address_line_2,
    city,
    state,
    zip,
    phone,
    phone_ext,

    square_footage,
    property_type,
    construction_type,
    asset_type,
    unit_count,
    individually_metered_units,
    electric_utility,
    gas_utility,
    water_utility,
  }) {
    return this._patch(`incentives/reports/property/${id}/`, {
      name,

      street_address,
      street_address_line_2,
      city,
      state,
      zip,
      phone,
      phone_ext,

      square_footage,
      property_type,
      construction_type,
      asset_type,

      unit_count,
      individually_metered_units,
      electric_utility,
      gas_utility,
      water_utility,
    });
  }

  getIncentiveReportPricingTiers() {
    return this._get('incentives/reports/pricing/').then(r => new ListResponseModel(r, IncentiveReportPricingTierModel));
  }

  removeIncentiveReportLocation(id) {
    return this._delete(`incentives/reports/property/${id}/`);
  }

  removeIncentiveReport(id) {
    return this._patch(`incentives/reports/${id}/`, {
      deleted: true,
    });
  }

  editIncentiveReport(id, {
    customer_first_name,
    customer_last_name,
    customer_job_title,
    customer_email,
    customer_phone,
    customer_phone_ext,
  }, {
    street_address,
    street_address_line_2,
    city,
    state,
    zip,
    phone,
    phone_ext,
  }) {
    return this._patch(`incentives/reports/${id}/`, {
      customer_first_name,
      customer_last_name,
      customer_job_title,
      customer_email,
      customer_phone,
      customer_phone_ext,
      billing_street_address: street_address,
      billing_street_address_line_2: street_address_line_2,
      billing_city: city,
      billing_state: state,
      billing_zip: zip,
      billing_phone: phone,
      billing_phone_ext: phone_ext,
    }).then(r => new IncentiveReportModel(r));
  }

  getEquipmentSavedFilters() {
    return this._get('company/equipments/ui_settings/').then(f => new EquipmentUiSettingsModel(f));
  }

  updateEquipmentFilters({
    search = null,
    sort = null,
    location_filters = null,
    rfp_filters = null,
    equipment_category_filters = null,
    tag_filters = null,
    status_filters = null,
    qr_code_assigned_filter = null,
    ui_selected_fields = null,
    ui_view_type = null,
  }) {
    const data = {
      search,
      sort,
      ui_view_type,
      location_filters: [],
      rfp_filters: [],
      equipment_category_filters: [],
      tag_filters: [],
      status_filters: [],
      qr_code_assigned_filter: null,
      ui_selected_fields: [],
    };

    if (location_filters?.length) {
      data.location_filters = location_filters.map(x => x.id || x);
    }

    if (rfp_filters?.length) {
      data.rfp_filters = rfp_filters.map(x => x.id || x);
    }

    if (equipment_category_filters?.length) {
      data.equipment_category_filters = equipment_category_filters.map(x => x.id || x);
    }

    if (tag_filters?.length) {
      data.tag_filters = tag_filters.map(x => x.id || x);
    }

    if (status_filters?.length) {
      data.status_filters = status_filters.map(x => x.id || x);
    }

    if (qr_code_assigned_filter !== null) {
      data.qr_code_assigned_filter = Object.prototype.hasOwnProperty.call(qr_code_assigned_filter, 'id') ? qr_code_assigned_filter.id : qr_code_assigned_filter;
    }

    if (ui_selected_fields?.length) {
      data.ui_selected_fields = ui_selected_fields.map(x => x.id || x);
    }

    return this._patch('company/equipments/ui_settings/', data).then(f => new EquipmentUiSettingsModel(f));
  }

  getCompanyPartUiSettings() {
    return this._get('company/parts/ui_settings/').then(f => new PartUiSettingsModel(f));
  }

  updateCompanyPartUiSettings({
    search = null,
    sort = null,
    tag_filters = null,
    equipment = null,
    ui_selected_fields = null,
    ui_view_type = null,
  }) {
    const data = {
      search,
      sort,
      tag_filters: [],
      equipment,
      ui_view_type,
      ui_selected_fields: [],
    };

    if (equipment === 0) {
      data.equipment = null;
    }

    if (tag_filters?.length) {
      data.tag_filters = tag_filters.map(x => x.id || x);
    }

    if (ui_selected_fields?.length) {
      data.ui_selected_fields = ui_selected_fields.map(x => x.id || x);
    }

    return this._patch('company/parts/ui_settings/', data).then(f => new PartUiSettingsModel(f));
  }

  getCompanyPartContainerUiSettings() {
    return this._get('company/parts/containers/ui_settings/').then(f => new PartUiSettingsModel(f));
  }

  updateCompanyPartContainerUiSettings({
    search = null,
    sort = null,
    tag_filters = null,
    location = null,
    ui_selected_fields = null,
    ui_view_type = null,
  }) {
    const data = {
      search,
      sort,
      tag_filters: [],
      location,
      ui_view_type,
      ui_selected_fields: [],
    };

    if (location === 0) {
      data.location = null;
    }

    if (tag_filters?.length) {
      data.tag_filters = tag_filters.map(x => x.id || x);
    }

    if (ui_selected_fields?.length) {
      data.ui_selected_fields = ui_selected_fields.map(x => x.id || x);
    }

    return this._patch('company/parts/containers/ui_settings/', data).then(f => new PartUiSettingsModel(f));
  }

  getCompanyLocationUiSettings() {
    return this._get('company/locations/ui_settings/').then(f => new LocationUiSettingsModel(f));
  }

  updateCompanyLocationUiSettings({
    search = null,
    sort = null,
    tag_filters = null,
    ui_selected_fields = null,
    ui_view_type = null,
    location_category = null,
  }) {
    const data = {
      search,
      sort,
      tag_filters: [],
      ui_view_type,
      ui_selected_fields: [],
      location_category,
    };

    if (location_category === 0) {
      data.location_category = null;
    }

    if (tag_filters?.length) {
      data.tag_filters = tag_filters.map(x => x.id || x);
    }

    if (ui_selected_fields?.length) {
      data.ui_selected_fields = ui_selected_fields.map(x => x.id || x);
    }

    return this._patch('company/locations/ui_settings/', data).then(f => new LocationUiSettingsModel(f));
  }

  getCsvExport(csvExportId) {
    return this._get(`company/csv_exports/${csvExportId}/`).then(c => new CsvExportModel(c));
  }

  getRiskAssessmentSurveys(limit = 50, offset = 0) {
    const params = {
      limit,
      offset,
    };
    return this._get('company/risk_assessment/surveys/', params).then(m => new ListResponseModel(m, RiskAssessmentSurveyModel));
  }

  getRiskAssessmentSurvey(surveyId) {
    return this._get(`company/risk_assessment/surveys/${surveyId}/`).then(m => new RiskAssessmentSurveyModel(m));
  }

  getRiskAssessmentSurveyResponses(surveyId) {
    return this._get(`company/risk_assessment/surveys/${surveyId}/responses/`).then(m => new ListResponseModel(m, RiskAssessmentSurveyModel));
  }

  getRiskAssessmentSurveyResponse(responseId) {
    return this._get(`company/risk_assessment/surveys/response/${responseId}/`).then(m => new RiskAssessmentSurveyModel(m));
  }

  createRiskAssessmentSurveyResponse(surveyId, question_responses) {
    return this._post(`company/risk_assessment/surveys/${surveyId}/responses/`, {
      question_responses, // { question_id, response }
    });
  }

  editRiskAssessmentSurveyResponse(responseId, question_responses) {
    return this._patch(`company/risk_assessment/surveys/response/${responseId}/`, {
      question_responses, // { question_id, response }
    });
  }

  submitRiskAssessmentSurveyResponse(responseId) {
    return this._post(`company/risk_assessment/surveys/response/${responseId}/submit/`);
  }

  // automated workflows

  getAutomatedWorkflows({ search, ordering, offset, limit }) {
    const params = {
      search,
      ordering,
      offset,
      limit,
    };
    return this._get('automated_workflows/', params).then(w => new ListResponseModel(w, AutomatedWorkflowModel));
  }

  getAutomatedWorkflow(id) {
    return this._get(`automated_workflows/${id}/`).then(w => new AutomatedWorkflowModel(w));
  }

  addWorkRequestWorkflow({
    name,
    description,
    active,
    condition_task_categories,
    condition_locations,
    condition_created_by_users,
    result_assigned_users,
    apply_to_existing,
  }) {
    return this._post('automated_workflows/', {
      name,
      description,
      active,
      condition_task_categories,
      condition_locations,
      condition_created_by_users,
      result_assigned_users,
      apply_to_existing,
    }).then(x => new AutomatedWorkflowModel(x));
  }

  updateWorkRequestWorkflow(id, {
    name,
    description,
    active,
    condition_task_categories,
    condition_locations,
    condition_created_by_users,
    result_assigned_users,
  }) {
    return this._patch(`automated_workflows/${id}/`, {
      name,
      description,
      active,
      condition_task_categories,
      condition_locations,
      condition_created_by_users,
      result_assigned_users,
    }).then(x => new AutomatedWorkflowModel(x));
  }

  updateWorkflowActive(id, active) {
    return this._patch(`automated_workflows/${id}/`, { active }).then(w => new AutomatedWorkflowModel(w));
  }

  deleteWorkflow(id) {
    return this._delete(`automated_workflows/${id}/`);
  }

  getWorkflowRequestMatches({
    condition_created_by_users = [],
    condition_task_categories = [],
    condition_locations = [],
  }) {
    const params = {};

    if (condition_created_by_users.length > 0) {
      params.condition_created_by_users = condition_created_by_users.join(',');
    }

    if (condition_task_categories.length > 0) {
      params.condition_task_categories = condition_task_categories.join(',');
    }

    if (condition_locations.length > 0) {
      params.condition_locations = condition_locations.join(',');
    }

    return this._get('automated_workflows/request_matches/', params);
  }
}
