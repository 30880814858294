const featureFlags = {
  vendor_hub_v2: 'Use Vendor Hub v2 views',
  payment_in_advance_manual_complete: 'If enabled, RFPs will not auto complete. Applies company only',
  equipment_qr_code_pdf: 'Allow Equipment PDFs from API',
  sp_dashboard_marketplace_metrics: 'show sp marketplace metrics on the sp dashboard',
  login_check_mfa: 'If enabled, and setup for the user account, require MFA check on login.',
  business_solutions: 'Use a re-purposed vendor hub v1 for HelixIntel solutions',
  company_risk_assessment_surveys: 'Allow EO company to see and response to the assigned risk assessment surveys',
  work_order_management: 'Allow user to request work/tasks that need to be approved. Should only be enabled at the company level.',
  bulk_update_tasks: 'Allow task table bulk inline actions',
  bulk_invite_users_to_company: 'Allow users to bulk add multiple users to a company by a list of email addresses',
  archive_locations: 'Allow users to archive locations and sublocations.',
  work_request_reviewers: 'Allow assignment of reviewers to work requests.',
  automated_workflows: 'Enable setting up automated workflows.',
  work_request_comment_count_v2: 'Showing unread vs total number of comments on the work requests table.',
  meshify_integration: 'Allows users to have a sensor device dashboard',
  reorder_projects: 'Ability for users to reorder projects in the work manager.',
  work_manager_comment_counts: 'Show unread vs total number of comments on the work manager for a task.',
  task_templates: 'Create and use task templates.',
  login_with_google: 'Allow users to login with Google.',
  login_with_ms: 'Allow users to login with Microsoft.',
  push_notification_settings: 'Show mobile push option in the notification settings.',
  customizations: 'Show the new customizations page, including the ability to create custom categories.',
  reservations: 'Create and request reservations.',
  seat_summary: 'Show the "Seat Summary" section of User Management to insurance customers.',
  disable_savings_scout: 'Remove Savings Scout and Payment Methods',
};

export { featureFlags };
